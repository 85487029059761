import config from 'stores/config'
import auth from 'stores/auth'
import profile from 'stores/profile'
import builder from 'stores/builder'
import fundraising from 'stores/fundraising'
import campaign from 'stores/campaign'
import driver from 'stores/driver'
import product from 'stores/product'
import popup from '../../../frontend/store/popup'
import shop from 'stores/shop'

let state = {
    stripePKey: window.appConfig.stripePKey
};

export default {
    modules: {
        config, auth, profile, builder, fundraising, campaign, driver,
        product,
        popup,
        shop
    },
    state: state
}