function fillInArea(src, dst) {
    const srcW = src.width
    const srcH = src.height
    const dstW = dst.width
    const dstH = dst.height
    const aspectRation = srcW / srcH
    let width = dstW
    let height = width / aspectRation
    if (height > dstH) {
        height = dstH
        width = height * aspectRation
    }
    return {width, height}
}

function setCenter(src, dst) {
    return (dst - src) / 2
}

export {fillInArea, setCenter}