const CAMPAIGNS_HOOPER_SETTINGS = {
  infiniteScroll: false,
  centerMode: false,
  playSpeed: 3500,
  mouseDrag: true,
  touchDrag: true,
  wheelControl: false,
  autoPlay: false,
  itemsToSlide: 1,
  breakpoints: {
    768: {
      itemsToShow: 2.4
    },
    680: {
      itemsToShow: 2.2
    },
    620: {
      itemsToShow: 2
    },
    560: {
      itemsToShow: 1.8
    },
    500: {
      itemsToShow: 1.6
    },
    470: {
      itemsToShow: 1.45
    },
    420: {
      itemsToShow: 1.3
    },
    390: {
      itemsToShow: 1.2
    },
    360: {
      itemsToShow: 1.1
    },
    320: {
      itemsToShow: 1
    }
  }
};

const FUNDRAISINGS_HOOPER_SETTINGS = {
  infiniteScroll: false,
  centerMode: false,
  playSpeed: 3500,
  mouseDrag: true,
  touchDrag: true,
  wheelControl: false,
  autoPlay: false,
  itemsToSlide: 1,
  breakpoints: {
    750: {
      itemsToShow: 2
    },
    700: {
      itemsToShow: 1.9
    },
    660: {
      itemsToShow: 1.8
    },
    600: {
      itemsToShow: 1.6
    },
    550: {
      itemsToShow: 1.42
    },
    500: {
      itemsToShow: 1.3
    },
    450: {
      itemsToShow: 1.2
    },
    420: {
      itemsToShow: 1.1
    },
    375: {
      itemsToShow: 1
    }
  }
};

const BOOST_HOOPER_SETTINGS = {
  infiniteScroll: true,
  centerMode: false,
  playSpeed: 3500,
  mouseDrag: true,
  touchDrag: true,
  wheelControl: false,
  autoPlay: false,
  itemsToSlide: 1,
  breakpoints: {
    1200: {
      itemsToShow: 4
    },
    1100: {
      itemsToShow: 3.5
    },
    1000: {
      itemsToShow: 3.2
    },
    900: {
      itemsToShow: 3
    },
    850: {
      itemsToShow: 2.7
    },
    768: {
      itemsToShow: 2.4
    },
    680: {
      itemsToShow: 2.2
    },
    620: {
      itemsToShow: 2
    },
    560: {
      itemsToShow: 1.8
    },
    500: {
      itemsToShow: 1.6
    },
    470: {
      itemsToShow: 1.45
    },
    420: {
      itemsToShow: 1.3
    },
    380: {
      itemsToShow: 1.2
    },
    340: {
      itemsToShow: 1.04
    },
    320: {
      itemsToShow: 1
    }
  }
};

const VEHICLE_INFO_HOOPER_SETTINGS = {
  infiniteScroll: true,
  centerMode: true,
  playSpeed: 3500,
  mouseDrag: true,
  touchDrag: true,
  wheelControl: false,
  autoPlay: false,
  itemsToSlide: 1
};

const CAR_PHOTO_HOOPER_SETTINGS = {
  infiniteScroll: false,
  centerMode: false,
  mouseDrag: true,
  touchDrag: true,
  wheelControl: false,
  autoPlay: false,
  itemsToSlide: 1,
  itemsToShow: 2.6,
  breakpoints: {
    460: {
      itemsToShow: 2.4
    },
    414: {
      itemsToShow: 2.2
    },
    370: {
      itemsToShow: 2
    },
    320: {
      itemsToShow: 1.7
    }
  }
};

const SLIDER_PHOTO_HOOPER_SETTINGS = {
  infiniteScroll: false,
  centerMode: false,
  mouseDrag: true,
  touchDrag: true,
  wheelControl: false,
  autoPlay: false,
  itemsToSlide: 1,
  itemsToShow: 2.6,
  breakpoints: {
    580: {
      itemsToShow: 3.2
    },
    510: {
      itemsToShow: 2.8
    },
    460: {
      itemsToShow: 2.4
    },
    414: {
      itemsToShow: 2.2
    },
    370: {
      itemsToShow: 2
    },
    320: {
      itemsToShow: 1.7
    }
  }
};

const COMBINED_CARDS_HOOPER_SETTINGS = {
  infiniteScroll: false,
  centerMode: false,
  mouseDrag: true,
  touchDrag: true,
  wheelControl: false,
  autoPlay: false,
  itemsToSlide: 1,
  itemsToShow: 2.6,
  breakpoints: {
    1200: {
      itemsToShow: 4
    },
    1120: {
      itemsToShow: 3.8
    },
    1060: {
      itemsToShow: 3.6
    },
    1020: {
      itemsToShow: 3.4
    },
    980: {
      itemsToShow: 3.2
    },
    940: {
      itemsToShow: 3
    },
    840: {
      itemsToShow: 2.7
    },
    750: {
      itemsToShow: 2.4
    },
    680: {
      itemsToShow: 2.1
    },
    610: {
      itemsToShow: 1.9
    },
    530: {
      itemsToShow: 1.6
    },
    450: {
      itemsToShow: 1.4
    },
    400: {
      itemsToShow: 1.2
    },
    375: {
      itemsToShow: 1.1
    },
    320: {
      itemsToShow: 1,
    }
  }
};

const VENDORS_HOOPER_SETTINGS = {
  infiniteScroll: true,
  centerMode: false,
  mouseDrag: true,
  touchDrag: true,
  wheelControl: false,
  autoPlay: false,
  itemsToSlide: 1,
  itemsToShow: 1,
}

export {
  CAMPAIGNS_HOOPER_SETTINGS,
  FUNDRAISINGS_HOOPER_SETTINGS,
  BOOST_HOOPER_SETTINGS,
  VEHICLE_INFO_HOOPER_SETTINGS,
  SLIDER_PHOTO_HOOPER_SETTINGS,
  CAR_PHOTO_HOOPER_SETTINGS,
  COMBINED_CARDS_HOOPER_SETTINGS,
  VENDORS_HOOPER_SETTINGS
};
