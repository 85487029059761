const CATEGORIES = [
  {
    name: "Photographer",
    name_plural: "Photographers",
    value: "photographer",
  },
  {
    name: "Videographer",
    name_plural: "Videographers",
    value: "videographer",
  },
  {
    name: "Social Media Manager",
    name_plural: "Social Media Managers",
    value: "social-media-manager",
  },
  {
    name: "Helmet Painter",
    name_plural: "Helmet Painters",
    value: "helmet-painter",
  },
  {
    name: "Coach",
    name_plural: "Coaches",
    value: "coach",
  },
  {
    name: "Mechanic",
    name_plural: "Mechanics",
    value: "mechanic",
  },
  {
    name: "Engineer",
    name_plural: "Engineers",
    value: "engineer",
  },
  {
    name: "Race Gear",
    name_plural: "Race Gears",
    value: "race-gear",
  },
  {
    name: "Teams",
    name_plural: "Teams",
    value: "teams",
  },
  {
    name: "Open Driver Opportunities",
    name_plural: "Open Driver Opportunities",
    value: "open-driver-opportunities",
  },
  {
    name: "Fitness Trainer",
    name_plural: "Fitness Trainers",
    value: "fitness-trainer",
  },
  {
    name: "Open Seats",
    name_plural: "Open Seats",
    value: "open-seats",
  },
  {
    name: "Graphic Designer",
    name_plural: "Graphic Designers",
    value: "graphic-designer",
  },
  {
    name: "Print Shop",
    name_plural: "Print Shops",
    value: "print-shop",
  },
  {
    name: "Mental Coach",
    name_plural: "Mental Coaches",
    value: "mental-coach",
  },
  {
    name: "Content Creator",
    name_plural: "Content Creators",
    value: "content-creator",
  },
  {
    name: "Other",
    name_plural: "Others",
    value: "other",
  },
];
export { CATEGORIES };
