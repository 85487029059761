

const Filters = {
    install(Vue, options) {
        Vue.filter('stripe', (value) =>{
            if(value)
                return parseInt(value)/100
        });
    }
};

export default Filters
