import axios from 'plugins/axios-instance'

export default {
    state: {
        loading: true,
        fundstandings: [],
        campaigns: [],
        driver: {}
    },
    actions: {
        async load({state}, id) {
            let resp = await Promise.all([
                await axios.get(`public/drivers/${id}`),
                await axios.get(`public/drivers/${id}/fundstandings`),
                axios.get(`public/drivers/${id}/campaigns`)
            ])
            state.driver = resp[0].data.driver
            state.fundstandings = resp[1].data.fundraisings
            state.campaigns = resp[2].data.campaigns
            state.loading = false
        }
    },
    namespaced: true
}
