<template>
  <section class="popup">
    <transition name="fade" mode="out-in">
      <div v-if="!overlayHidden" @click.self="closePopup" class="popup__overlay"></div>
    </transition>
    <transition :name="isSmall ? 'modalSlide' : 'fade'" mode="out-in">
      <div v-if="!containerHidden" class="popup__form-container">
        <h2 class="popup__heading">{{!status ? 'Disabling' : 'Enabling' }} a profile</h2>
        <p class="popup__subtitle">
          <template v-if="!status">
            With your profile turned off, your audience will not see your {{ profileType }} profile.
            Are you sure you want to deactivate your {{ profileType }} profile?
          </template>
          <template v-else>
            If your profile is enabled, your audience will be able to view your {{ profileType }} profile.
            Are you sure you want to enable your {{ profileType }} profile?
          </template>
        </p>
        <div class="popup__footer">
          <b-button
            class="popup__btn"
            @click.prevent="$emit('cancel')"
            type="is-primary is-large is-outlined"
          >
            Cancel
          </b-button>
          <b-button
            class="popup__btn"
            @click.prevent="$emit('confirm')"
            type="is-primary is-large"
            :loading="loading"
          >
            {{ !status ? 'Deactivate' : 'Turn on' }}
          </b-button>
        </div>
        <button
          class="popup__btn-close"
          type="reset"
          name="close"
          @click="closePopup"
        >
          <img class="popup__close-icon" src="../assets/images/close.svg" alt="close" />
        </button>
        <button
          class="popup__btn-swipe-close"
          type="reset"
          name="swipe-close"
          @touchstart="onTouchStart"
          @touchmove="onTouchMove"
        >
          <img class="popup__swipe-close-icon" src="../assets/images/swipe-close.svg" alt="close" />
        </button>
      </div>
    </transition>
  </section>
</template>

<script>
import { windowResize } from '../mixins/window_resize';
import { modalLogic } from '../mixins/modal_logic';

export default {
  mixins: [ windowResize, modalLogic ],

  props: {
    status: null,
    loading: false
  },

  computed: {
    profileType() {
      return this.$route.name === 'driver_profile' ? 'racer' : 'professional'
    }
  },
}
</script>

<style lang="scss" scoped>
.popup {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Montserrat", "Quicksand", "Source Sans Pro", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

    &__input-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  }
}

.popup__form-container {
  position: relative;
  max-width: 580px;
  height: fit-content;
  background: #333;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: auto;
  padding: 40px;


  @media screen and (max-width: 480px) {
    max-width: none;
    width: 100%;
    padding: 40px 20px 56px;
    border-radius: 6px 6px 0 0;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
  }
}

::placeholder {
  color: rgb(203, 203, 203);
}

.popup__heading {
  margin-bottom: 0;
  font-family: 'Bebas Neue', sans-serif;
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.54px;
  text-transform: uppercase;
  text-transform: uppercase;
}

.popup__subtitle{
  font-family: "Montserrat", "Quicksand", "Source Sans Pro", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 16px 0 28px !important;
  align-self: flex-start;
}

.popup__btn-close {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 20px;
  right: 21px;
  padding: 0;
  border: none;
  outline: none;
  background: inherit;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.popup__btn-swipe-close {
    display: none;
    width: 100px;
    height: 30px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0;
    border: none;
    outline: none;
    background: inherit;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

.popup__btn-close:hover {
  transform: rotate(180deg);
}

@media screen and (max-width: 480px) {
  .popup__btn-close {
    display: none;
  }
  .popup__btn-swipe-close {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.popup__close-icon {
  object-fit: cover;
  width: 32px;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: 1;
}

.popup__swipe-close-icon {
  object-fit: cover;
  width: 39px;
}
</style>