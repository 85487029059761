<template>
  <div>
    <div class="navbar-container">
      <div class="container">
        <b-navbar :active.sync="burgerActive">
          <template slot="brand">
            <b-navbar-item class="navbar__logo" :href="homePage">
                <img :src="logo">
            </b-navbar-item>
          </template>
          <template slot="start" v-if="user">
          </template>

          <template slot="end">
            <template v-if="!user || !user.driver">
              <b-navbar-item class="no-uppercase" href="/racers">
                Racers
                </b-navbar-item>
                <b-navbar-item class="no-uppercase" href="/sponsors">
                  Sponsors
                </b-navbar-item>
                <b-navbar-item class="no-uppercase" href="/fans">
                  Fans
                </b-navbar-item>
              </template>
              <b-navbar-item v-if="user && user.driver"
                             class="navbar__shop" 
                             tag="router-link" 
                             :to="{ path: '/my-shop' }">
                  My merch shop
                  <span>NEW</span>
                </b-navbar-item>
            <b-navbar-dropdown :class="['platform-dropdown', {'logged-in': user && user.driver}]">
              <template slot="label">
                <span class="platform">PLATFORM</span>
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0L5 5L10 0H0Z" fill="black"/>
                </svg>
              </template>
              <template v-if="user && user.driver">
                <b-navbar-item class="no-uppercase" href="/racers">
                  Racers
                </b-navbar-item>
                <b-navbar-item class="no-uppercase" href="/sponsors">
                  Sponsors
                </b-navbar-item>
                <b-navbar-item class="no-uppercase" href="/fans">
                  Fans
                </b-navbar-item>
              </template>
              <b-navbar-item href="/about-us">
                About us
              </b-navbar-item>
              <b-navbar-item href="/success-story">
                Success stories
              </b-navbar-item>
              <b-navbar-item href="/sponsorship">
                Sponsorship opportunities
              </b-navbar-item>
              <b-navbar-item href="/campaigns">
                Specific sponsorship
              </b-navbar-item>
              <b-navbar-item href="/fundraisings">
                Open sponsorship
              </b-navbar-item>
              <b-navbar-item href="/vendors">
                Industry professionals
              </b-navbar-item>
              <b-navbar-item href="/drivers">
                Boost
              </b-navbar-item>
              <b-navbar-item href="/blog">
                Blog
              </b-navbar-item>
              <b-navbar-item href="/faq">
                FAQ
              </b-navbar-item>
            </b-navbar-dropdown>
            <b-navbar-item tag="div" v-if="!user">
              <div class="buttons">
                  <router-link to="/signup/racer"
                               :class="['button', 'is-primary', `${isTablet ? 'is-large' : 'is-small'}`]">
                    Join now
                  </router-link>
              </div>
            </b-navbar-item>
            <b-navbar-item class="no-uppercase" style="text-align: center" href="/login" v-if="!user">
              LOG IN
            </b-navbar-item>
            <b-navbar-dropdown
              right
              @active-change="handleDropdown"
              :active="userDropdownActive"
              :class="`${user.driver ? 'user-dropdown' : 'sponsor-dropdown'}`"
              v-if="user"
            >
              <template slot="label">
                <span class="no-uppercase name"><img class="navbar-user-icon" :src="user.avatar"> {{user.name}}</span>
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0L5 5L10 0H0Z" fill="black"/>
                </svg>
              </template>
              <b-navbar-item tag="router-link" :to="{ path: '/home' }">
                My activities
              </b-navbar-item>
              <b-navbar-item v-if="user.driver" class="navbar__shop" tag="router-link" :to="{ path: '/my-shop' }">
                My merch shop
                <span>NEW</span>
              </b-navbar-item>
              <b-navbar-item
                v-if="user.driver"
                :href="`/profile/${user.friendly_id}`"
                :class="{'deactivated': !isDriverOn || !user.profile_enabled}"
              >
                View racer profile
              </b-navbar-item>
              <b-navbar-item
                v-if="user.driver"
                :href="`/vendors/${user.friendly_id}`"
                :class="{'deactivated': !isProfessionalOn || !user.professional_profile.enabled}"
              >
                View professional profile
              </b-navbar-item>
              <b-navbar-item tag="router-link" :to="{ path: '/edit-profile' }">
                Edit profile
              </b-navbar-item>
              <b-navbar-item href="/history" v-if="user.driver">
                Sales history
              </b-navbar-item>
              <b-navbar-item href="/history" v-else-if="user.supporter">
                Purchase history
              </b-navbar-item>
              <b-navbar-item @click="logOut">
                Log out
              </b-navbar-item>
            </b-navbar-dropdown>
          </template>

        </b-navbar>
      </div>
    </div>
    <StripeSetupBanner v-if="this.user && this.user.driver" />
  </div>
</template>


<script>
import { mapState, mapMutations } from 'vuex';
import Logo from '../../assets/images/logo_dark.svg'
import StripeSetupBanner from "./stripe_setup_banner/StripeSetupBanner.vue"
import { windowResize } from './../mixins/window_resize'

    export default {
        mixins: [ windowResize ],
        components: { StripeSetupBanner },
        props: ['user'],

        created() {
          if (this.user) {
            this.setIsDriverOn(this.user.profile_enabled)
            this.setIsProfessionalOn(this.user.professional_profile ? this.user.professional_profile.enabled : false)
          }
        },

        data: function () {
            return {
                logo: Logo,
                homePage: location.protocol + '//' + location.host,
                burgerActive: false,
                userDropdownActive: false,
            }
        },
        computed: {
          ...mapState('profile', ['isDriverOn', 'isProfessionalOn'])
        },
        methods: {
          ...mapMutations('profile', ['setIsDriverOn', 'setIsProfessionalOn']),
          logOut(){
            if (localStorage.fundchaserUrl) localStorage.removeItem('fundchaserUrl');
            this.$emit('log-out');
          },
          handleDropdown(val) {
            setTimeout(() => {
              this.userDropdownActive = val
            }, 0)
          },
        },

        watch: {
          user: {
            handler(val) {
              if (val) {
                this.setIsDriverOn(this.user.profile_enabled)
                this.setIsProfessionalOn(this.user.professional_profile ? this.user.professional_profile.enabled : false)
              }
            }
          },
          burgerActive(val) {
            this.handleDropdown(val)
          }
        },
    }
</script>

<style lang="sass">
  .navbar-container
    position: sticky
    top: 0
    display: flex
    place-items: center
    height: 104px
    background: #fff
    z-index: 230
    @media screen and (max-width: 1023px)
      height: 60px

  .navbar
    background: #fff
    @media screen and (max-width: 1023px)
      padding: 0 16px

    .navbar-item.has-dropdown.user-dropdown,
    .navbar-item.has-dropdown.sponsor-dropdown,
    .navbar-item.has-dropdown.platform-dropdown
      .navbar-link
        &:hover
          color: #F33E31
          background: none
        @media screen and (max-width: 1023px)
          &:hover
            background: #F5F5F5
          &:hover .name
            color: #F33E31

      .navbar-dropdown
        height: 0px
        display: block
        transition: all 0.2s ease-in

    .navbar-item.has-dropdown.user-dropdown
      &.is-active
        .navbar-dropdown
          height: calc(49px * 7)

    .navbar-item.has-dropdown.sponsor-dropdown
      &.is-active
        .navbar-dropdown
          height: calc(49px * 4)

    .navbar-item.has-dropdown.platform-dropdown
      &.is-active
        .navbar-dropdown
          height: calc(49px * 9)
      &.is-active.logged-in
        .navbar-dropdown
          height: calc(49px * 12)

    .navbar-dropdown
      background: #fff
      padding: 0
      border-radius: 4px
      overflow: hidden
      width: 250px
      transition: all 0.2s ease-in
      border: none
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2)

      a.navbar-item
        padding: 12px 20px
        background: rgba(255, 255, 255, 0.05)
        margin-bottom: 1px
        letter-spacing: 0.04em
        text-transform: none !important
        font-weight: 400
        font-size: 16px
        line-height: 24px
        transition: all 0.3s ease-in
        color: #000
        &:hover
          color: #F33E31
          background: #F5F5F5

        &.deactivated
          color: #B3B3B3
          pointer-events: none;
          cursor: not-allowed;

        &.has-dropdown.user-dropdown,
        &.has-dropdown.sponsor-dropdown,
        &.has-dropdown.platform-dropdown,
          .navbar-dropdown
            height: 0px
            transition: all .2s ease-in

    .navbar-menu.is-active
      @media (max-width: 1023px)
        width: 100%
        position: absolute
        left: 0
        padding: 0
        background-color: #fff
        border-radius: 4px
        box-shadow: inherit
        overflow: hidden
        .navbar-item.has-dropdown
          padding: 0

          .navbar-link
            display: none

          &.user-dropdown, &.sponsor-dropdown, &.platform-dropdown
            .navbar-dropdown
              transition: all .2s ease-in
            .navbar-link
              display: flex
              align-items: center
              padding: 14px 20px
              &::after
                transition: all .2s ease-in
                transform: rotate(-45deg)

          &.is-active
            .navbar-link
              display: flex
              align-items: center
              padding: 14px 20px
              &::after
                transition: all .2s ease-in
                transform: rotate(-45deg)

          &.is-active
            .navbar-link
              &::after
                transition: all .2s ease-in
                transform: rotate(135deg)

          .navbar-dropdown
            background: #fff
            padding: 0
            border-radius: 4px
            overflow: hidden
            width: 100%
            transition: all 0.3s ease-in
            border: none
            box-shadow: none

            a.navbar-item
              padding: 12px 60px
              background: rgba(255, 255, 255, 0.05)
              margin-bottom: 1px
              text-transform: none !important
              font-weight: 400
              font-size: 16px
              line-height: 24px
              letter-spacing: 0.04em
              transition: all 0.3s ease-in
              color: #000
              &:hover
                color: #F33E31
                background: #F5F5F5

              &.deactivated
                color: #B3B3B3
                pointer-events: none;
                cursor: not-allowed;

        .navbar-item
          padding: 14px 20px

      @media (max-width: 375px)
        position: fixed
        top: 50px
        right: 0
        bottom: 0
        left: 0
        box-shadow: none
    .navbar-brand
      margin-left: 0 !important

      .navbar-item
        padding: 0 20px 0 10px
        margin-right: 75px

      .navbar-burger.burger
        &:hover
          background: none

        span
          left: calc(50% - 12px)
          width: 24px
          height: 2px
          border-radius: 4px
          background-color: #000

    .navbar-user-icon
      width: 32px
      height: 32px
      min-width: 32px
      min-height: 32px
      border-radius: 50%
      margin-right: 8px
      object-fit: cover

    .navbar-link
      padding: 0 20px 0 0
      color: #F33E31
      font-size: 16px
      letter-spacing: 0.88px
      line-height: 24px
      transition: all 0.3s ease-in
      &:hover
        background: transparent

      .no-uppercase
        text-transform: none
        align-items: center
        display: flex

      .name, .platform
        color: #000
        transition: all .2 ease-in
        font-weight: 500
        font-size: 16px
        line-height: 24px

      &:not(.is-arrowless)
        padding-right: 0
        &::after
          display: none


        &:hover
          &::after
            border-color: #303036

    a.navbar-item
      padding: 0 20px
      color: #000
      font-size: 14px
      font-weight: 500
      text-transform: uppercase !important
      line-height: 20px
      letter-spacing: 0.04em
      transition: all 0.3s ease-in
      &:hover
        color: #F33E31
        background: #F5F5F5

      &.no-uppercase
        text-transform: none

      &.navbar__logo
        width: 256px
        height: 66px
        padding: 0
        @media screen and (max-width: 1023px)
          width: 176px
          height: 48px

        img
          width: 100%
          height: 100%
          max-height: none

    a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active
      color: #F33E31
      background: none

      .navbar-link
        background: none !important

    .navbar-end
      @media screen and (max-width: 1023px)
        display: flex
        flex-direction: column
        padding-bottom: 12px
      .navbar-item
        padding: 0 15px
        @media screen and (max-width: 1023px)
          &:is(:not(.no-uppercase))
            &:hover
              background: none

          &:hover
            background: #F5F5F5

    .platform-dropdown
      span
        font-size: 14px !important
        font-weight: 500 !important
      .navbar-dropdown
        width: auto
      .navbar-link
        padding-right: 0
      @media screen and (max-width: 1023px)
        order: 0
        &.logged-in
          order: 3

    &__shop
      display: flex
      align-items: center
      gap: 8px

      span
        display: inline-block
        padding: 2px 6px
        font-size: 12px
        line-height: 16px
        font-weight: 600
        color: #fff
        border-radius: 30px
        background: #F33E31
</style>
<style lang="scss" scoped>
.container{
  max-width: 1220px !important;
  padding: 0 16px;
  @media screen and (max-width: 1023px) {
    padding: 0;
  }
}

.navbar-link{
  align-items: center;
  svg{
    margin-left: 15px;
    transition: all 0.3s ease-in
  }
}

.navbar-item.has-dropdown.is-active{
  svg{
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 1023px) {
.navbar-menu.is-active{
a.navbar-item{
  margin-bottom: 0 !important;
  &:nth-of-type(1){
    padding-top: 26px !important;
  }
}
}
  .buttons a.button {
    width: 100%;
  }
}
</style>
<style lang="scss">
.navbar-item.has-dropdown.is-active .navbar-link{
  background: none;
}
</style>
