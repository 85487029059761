import axios from "axios";
const COOKIE_EXPIRATION = 7 * 24 * 60 * 60 * 1000 //1 week
const COOKIE_NAME = 'popup_shown'
const POPUP_TIMEOUT = 3000

function checkCookie() {
  return document.cookie.indexOf(COOKIE_NAME) != -1
}

function setCookie() {
  let date = new Date(Date.now() + COOKIE_EXPIRATION);
  date = date.toUTCString();
  document.cookie = `${COOKIE_NAME}=1; path=/; expires=${date}`
}

export const state = () => ({
  popupTitle: '',
  popupBody: '',
  isWelcomePopupOpen: false
})

export const mutations = {
  SET_WELCOME_POPUP(state, {enabled, title, body}) {
    state.isWelcomePopupOpen = enabled
    state.popupTitle = title
    state.popupBody = body
  }
}
export const actions = {
  closeWelcomePopup({commit}) {
    commit('SET_WELCOME_POPUP', {enabled: false})
    setCookie()
  },
  async getPopupData({commit}) {
    if (checkCookie())
      return
    let {data} = await axios.get('/api/public/popups')
    if (data.enabled)
      setTimeout(() => commit('SET_WELCOME_POPUP', data), POPUP_TIMEOUT)
  }
}

export default {
  namespaced: true,
  actions: actions,
  mutations: mutations,
  state: state
}
