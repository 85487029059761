import {fillInArea, setCenter} from "../libs/image_utils";
import {
    storeArtwork,
    removeArtwork,
    LOCATIONS_USED,
    LOCATIONS_FRONT,
    LOCATIONS_BACK,
    dataURItoBlob
} from "../libs/canvas";
import {v4 as uuidv4} from 'uuid';
import axios from 'plugins/axios-instance'
import {generatePrintLocation, generateProductVariant} from 'libs/canvas'

const FIXED_MARKUP_USD = 'fixed_markup_usd'
const FIXED_MARKUP_PERCENTAGE = 'fixed_markup_percentage'

export default {
    namespaced: true,
    state: {
        product: null,
        availableVariants: [],
        variants: [],
        selectedColor: null,
        selectedLayer: null,
        selectedLayerIndex: null,
        printLocations: [],
        activeArtworkIdx: -1,
        canvasSize: {},
        artworks: [],
        previews: [],
        previewsGenerated: false,
        price: 0,
        name: '',
        description: '',
        priceMarkup: 10,
        pricingType: FIXED_MARKUP_USD,
        nestedPanel: null
    },
    mutations: {
        SET_ARTWORK_LOCATION(state, location) {
            let artwork = state.artworks[state.activeArtworkIdx]
            let printLocation = state.printLocations.find((l) => l.id == location)
            if (printLocation) {
                artwork.size = fillInArea({width: artwork.originalWidth, height: artwork.originalHeight}, printLocation)
                if (artwork.size.width >= artwork.size.height)
                    artwork.position.top = setCenter(artwork.size.height, printLocation.height)
                else
                    artwork.position.left = setCenter(artwork.size.width, printLocation.width)
            } else {
                artwork.size = {width: 0, height: 0}
                artwork.position = {left: 0, height: 0}
            }
            artwork.location = location
            state.previewsGenerated = false
        },
        SET_ARTWORK_SIZE(state, size) {
            state.artworks[state.activeArtworkIdx].size = {...size}
            state.previewsGenerated = false
        },
        SET_ARTWORK_POSITION(state, position) {
            state.artworks[state.activeArtworkIdx].position = {...position}
            state.previewsGenerated = false
        },
        SET_ARTWORK_CENTER_H(state) {
            let artwork = state.artworks[state.activeArtworkIdx]
            if (!!!artwork)
                return
            const printLocation = state.printLocations.find((l) => l.id == artwork.location)
            if (printLocation) {
                const left = setCenter(artwork.size.width, printLocation.width)
                artwork.position.left = left
            }
            state.previewsGenerated = false
        },
        SET_ARTWORK_CENTER_V(state) {
            let artwork = state.artworks[state.activeArtworkIdx]
            if (!!!artwork)
                return
            const printLocation = state.printLocations.find((l) => l.id == artwork.location)
            if (printLocation) {
                const top = setCenter(artwork.size.height, printLocation.height)
                artwork.position.top = top
            }
            state.previewsGenerated = false
        },
        SET_ACTIVE_ARTWORK(state, {id}) {
            state.activeArtworkIdx = state.artworks.findIndex((a) => a.id == id)
        },
        SET_ARTWORK_ARRANGEMENT(state) {
            if (state.artworks.length)
                for (let i = 0; i < state.artworks.length; i++) {
                    let artwork = state.artworks[i]
                    let printLocation = state.printLocations.find((l) => l.id == artwork.location)
                    if (printLocation) {
                        artwork.size = fillInArea({
                            width: artwork.originalWidth,
                            height: artwork.originalHeight
                        }, printLocation)

                    }

                }
            state.previewsGenerated = false
        },
        SET_PRODUCT(state, product) {
            state.product = {...product};
            state.variants = [...product.availableVariants.slice(0, 2)]
            state.availableVariants = [...product.availableVariants]
            state.previewsGenerated = false
        },
        SET_SELECTED_COLOR(state, color) {
            state.selectedColor = color
            state.previewsGenerated = false
        },
        SET_SELECTED_LAYER(state, layer) {
            state.selectedLayer = layer
        },
        SET_SELECTED_LAYER_INDEX(state, index) {
            state.selectedLayerIndex = index
        },
        UPDATE_PRODUCT(state, product) {
            state.product = {...state.product, ...product}
        },
        TOGGLE_VARIANT(state, variant) {
            const addedVariantIdx = state.variants.findIndex((v) => v.name == variant.name)
            if (addedVariantIdx > -1)
                state.variants.splice(addedVariantIdx, 1)
            else
                state.variants.push(variant)
            state.previewsGenerated = false
        },
        SET_CANVAS_SIZE(state, {side, width, height}) {
            state.canvasSize[side] = {width, height}
        },
        ADD_ARTWORK(state, artwork) {
            state.artworks.push(artwork)
            state.previewsGenerated = false
        },
        SET_PRINT_LOCATIONS(state, {printLocations}) {
            state.printLocations = []
            for (let l of Object.keys(printLocations)) {
                if (LOCATIONS_USED.indexOf(l) == -1)
                    continue
                const {
                    startXPercent,
                    startYPercent,
                    endXPercent,
                    endYPercent
                } = printLocations[l].imagePlacement
                const canvas = LOCATIONS_FRONT.indexOf(l) == -1 ? state.canvasSize.back : state.canvasSize.back
                state.printLocations.push({
                    id: l,
                    left: canvas.width * startXPercent / 100,
                    top: canvas.height * startYPercent / 100,
                    width: canvas.width * (endXPercent - startXPercent) / 100,
                    height: canvas.height * (endYPercent - startYPercent) / 100,
                    imagePlacement: printLocations[l].imagePlacement,
                    physicalSize: printLocations[l].physicalSize
                })
            }
            state.previewsGenerated = false
        },
        REMOVE_ARTWORK(state, id) {
            let idx = state.artworks.findIndex((a) => a.id == id)
            if (idx > -1) {
                removeArtwork(state.artworks[idx].id)
                state.artworks.splice(idx, 1)
                state.activeArtworkIdx = null

            }
            state.previewsGenerated = false
        },
        SET_PRINT_LOCATION_IMAGE(state, {idx, image}) {
            state.printLocations[idx].image = image
        },
        SET_PREVIEW_STATUS(state, status) {
            state.previewsGenerated = status
        },
        SET_PRICE(state, price) {
            state.price = price
        },
        SET_NAME(state, {name}) {
            state.name = name
        },
        SET_DESCRIPION(state, {description}) {
            state.description = description
        },
        SET_PRICE_MARKUP(state, {pricingType, priceMarkup}) {
            if (pricingType) {
                state.pricingType = pricingType
                switch (pricingType) {
                    case FIXED_MARKUP_USD:
                        break
                    case FIXED_MARKUP_PERCENTAGE:
                        if (state.priceMarkup > 100)
                            state.priceMarkup = 100
                        break
                }
            }
            if (priceMarkup != undefined) {
                state.priceMarkup = priceMarkup >= 0 ? priceMarkup : 0
                switch (state.pricingType) {
                    case FIXED_MARKUP_USD:
                        break
                        case FIXED_MARKUP_PERCENTAGE:
                            if (state.priceMarkup > 100)
                                state.priceMarkup = 100
                            break
                }
            }
        },
        SET_NESTED_PANEL(state, panel) {
            state.nestedPanel = panel
        },
        SET_PREVIEWS(state, previews) {
            state.previews = previews
        },
        RESET_PRODUCT(state) {
            state.product = {}
            state.variants = []
            state.name = ''
            state.description = ''
            state.priceMarkup = 10
            state.pricingType = FIXED_MARKUP_USD
        },
        SET_PRODUCT_NULL(state) {
            state.product = null
        },
    },
    actions: {
        async addArtwork({commit, state}, artwork) {
            let id = uuidv4()
            let img = await storeArtwork({id: id, url: artwork.url})
            let size = {width: 0, height: 0}
            let position = {left: 0, top: 0}
            let printLocation = state.printLocations.find((l) => l.id == artwork.location)
            if (printLocation) {
                size = fillInArea({width: img.originalWidth, height: img.originalHeight}, printLocation)
                if (size.width < size.height)
                    position.left = setCenter(size.width, printLocation.width)
                else
                    position.top = setCenter(size.height, printLocation.height)
            }

            commit("ADD_ARTWORK", {
                ...img, ...{
                    size: size,
                    position: position,
                    location: artwork.location,
                    name: artwork.name,
                    id
                }
            })
        },
        async setProduct({commit, dispatch}, product) {
            await dispatch('setSizes', product.availableVariants[0].images)
            commit('SET_ACTIVE_ARTWORK', {id: -1})
            commit('SET_PRODUCT', product)
            commit('SET_PRINT_LOCATIONS', product)
            commit('SET_ARTWORK_ARRANGEMENT')
            commit('SET_SELECTED_COLOR', product.colors[0])
        },
        setSizes({state, commit}, images) {
            return Promise.all(
                Object.keys(images).map((side) => {
                    return new Promise(function (resolve, reject) {
                        fabric.Image.fromURL(images[side], (img) => {
                            commit('SET_CANVAS_SIZE', {width: img.width, height: img.height, side})
                            resolve()
                        })
                    });
                })
            )
        },
        async setPreviews({ commit, state }, previews) {
            const generatedPreviews = []
            for (let preview of previews) {
              generatedPreviews.push(preview.images.front)
              if (preview.images.back) {
                generatedPreviews.push(preview.images.back)
              }
            }
            commit('SET_PREVIEWS', generatedPreviews)
        },
        setNestedPanel({commit}, panel) {
            commit('SET_NESTED_PANEL', panel)
        },
        setSelectedColor({commit}, color) {
            commit('SET_SELECTED_COLOR', color)
        },
        setSelectedLayer({commit}, layer) {
            commit('SET_SELECTED_LAYER', layer)
        },
        setSelectedLayerIndex({commit}, index) {
            commit('SET_SELECTED_LAYER_INDEX', index)
        },
        updateProduct({commit}, product) {
            commit('UPDATE_PRODUCT', product)
        },
        async updateProductOnServer({ commit }, productData) {
            try {
                const { id, ...data } = productData
                const response = await axios.put(`shop/products/${id}`, data)
                commit('UPDATE_PRODUCT', response.data)
            } catch (error) {
                console.error('Error updating product:', error)
                throw error
            }
        },
        async createProduct({state, getters}, status) {
            const data = new FormData();
            data.append('status', status)
            data.append('name', state.name)
            data.append('price_markup', state.pricingType)
            data.append('price_markup_value', state.priceMarkup)
            data.append('description', state.description)
            data.append('catalog_product_id', state.product.id)
            data.append('printing_method', state.product.printingMethod)
            for (let variant of state.variants) {
                let previewData = await generateProductVariant(variant, state.printLocations, state.artworks)
                data.append(`shop_variants_attributes[]color`, previewData.name)
                data.append(`shop_variants_attributes[]hex_code`, previewData.hexCode)
                data.append(`shop_variants_attributes[]front`, dataURItoBlob(previewData.images.front), 'preview.jpeg')
                data.append(`shop_variants_attributes[]back`, dataURItoBlob(previewData.images.back), 'preview.jpeg')
            }
            for (let pl of state.printLocations) {
                let img = await generatePrintLocation(state.artworks, pl, 3)
                if (img) {
                    data.append(`shop_artworks_attributes[]location`, pl.id)
                    data.append(`shop_artworks_attributes[]file`, dataURItoBlob(img), 'preview.jpeg')
                    data.append(`shop_artworks_attributes[]width`, pl.physicalSize.widthInches)
                    data.append(`shop_artworks_attributes[]height`, pl.physicalSize.heightInches)
                }
            }
            await axios.post(
                "shop/products",
                data
            );

            // let blob = dataURItoBlob(image)
            // data.append("artworks[" + this.printLocations[idx].id + "]", blob);
        }
    },
    getters: {
        availablePrintLocations(state) {
            return state.printLocations.map((p) => p.id)
        },
        artwork(state) {
            if (state.activeArtworkIdx > -1)
                return state.artworks[state.activeArtworkIdx]
            else
                return null
        },
        locations(state) {
            let locations = {}
            locations.front = state.printLocations.filter((l) => LOCATIONS_FRONT.indexOf(l.id) > -1)
            locations.back = state.printLocations.filter((l) => LOCATIONS_BACK.indexOf(l.id) > -1)
            return locations
        },
        selectedColors(state) {
            return state.variants.map((v) => v.name)
        },
        activeDesign(state) {
        },
        selectedColor(state) {
            return state.selectedColor
        },
        selectedLayer(state) {
            return state.selectedLayer
        },
        profit(state, getters) {
            if (state.product && state.product.cost) {
                return getters.salesPrice - state.product.cost
            }
            return 0
        },
        locationsWithPrint(state) {
            let locations = {}
            for (let artwork of state.artworks) {
                if (artwork.location) {
                    if (locations[artwork.location]) {

                    } else {
                        locations[artwork.location] = artwork.location
                    }
                }
            }
            return Object.keys(locations)
        },
        printingPrice(state, getters) {
            return state.product.price + state.product.print_price * getters.locationsWithPrint.length
        },
        salesPrice(state, getters) {
            let price = getters.printingPrice
            if (state.priceMarkup < 0)
                state.priceMarkup = 0
            switch (state.pricingType) {
                case FIXED_MARKUP_USD:
                    return price + state.priceMarkup * 100
                case FIXED_MARKUP_PERCENTAGE:
                    return price * (1 + state.priceMarkup / 100.0)
            }
        },
        flattenedPreviews(state) {
            return state.previews
        },

    },
}
export {FIXED_MARKUP_USD, FIXED_MARKUP_PERCENTAGE}
// printLocation:

// cap_front, cap_front_left, cap_front_right,
// left_hip, right_hip, left_leg, right_leg,
// bottom_center, corner,
// drinkware_front, middle_front_pocket, bottom_front_pocket,
// top_center, neck_label,
// on_left_pocket, on_right_pocket