import axios from 'axios'

// const state = function () {
//     return {
//         user: window.currentUser,
//
//     }
// };
const state = {
    user: window.currentUser
};
const getters = {
    chargesEnabled(state){
        return state.user.charges_enabled
    },
    detailsSubmitted(state){
        return state.user.details_submitted
    },
    payoutsEnabled(state){
        return state.user.payouts_enabled
    },
    payoutsApproved(state){
        return state.user.payouts_approved
    },
    stripeCreated(state){
        return state.user.stripe_created
    },
    racerInfo(state) {
        return {
            racer_bio: state.user.bio,
            racer_hometown: state.user.hometown,
            racer_name: state.user.name,
            racer_social: state.user.social,
            racer_facebook_url: state.user.facebook_url,
            racer_instagram_url: state.user.instagram_url,
            racer_youtube_url: state.user.youtube_url,
            racer_tiktok_url: state.user.tiktok_url,
            racer_snapchat_url: state.user.snapchat_url,
            racer_twitter_url: state.user.twitter_url,
            racer_linkedin_url: state.user.linkedin_url,
            racer_other_url: state.user.other_url,
            team_name: state.user.team,
            team_url: state.user.team_url
        }
    }
};
const mutations = {
    SET_STRIPE_DISCONNECTED(state){
        state.user.charges_enabled = false
        state.user.details_submitted = false
        state.user.payouts_enabled = false
        state.user.stripe_created = false
    },
    setUser(state, data) {
        state.user = data;
    }
};
const actions = {
    impersonate({commit}, {token}) {
        try {
            const {data} = axios.post('/api/users/impersonate', {token})
            commit('setUser', data);
        } catch (e) {
            alert('Try again later')
        }
    },
    updateProfile({state, commit}, payload) {
        return new Promise((resolve, reject) =>
            axios.put('/api/profile', payload).then((resp) => {
                    commit('setUser', resp.data);
                    resolve()
                }
            ).catch((err) => {
                console.log(err);
                reject(err.response.data)
            })
        )
    },
    resetPassword({state}, payload) {
        return new Promise((resolve, reject) =>
            axios.put('/api/users/password.json', {user: payload}).then((resp) => {
                    state.user = resp.data;
                    resolve()
                }
            ).catch((err) => {
                reject(err.response.data)
            })
        )
    },
    requestPasswordReset({state}, payload) {
        return new Promise((resolve, reject) =>
            axios.post('/api/users/password.json', {user: payload}).then((resp) => {
                    state.user = resp.data;
                    resolve()
                }
            ).catch((err) => {
                console.log(err);
                reject(err.response.data)
            })
        )
    },
    updatePassword({state}, payload) {
        return new Promise((resolve, reject) =>
            axios.put('/api/users', {user: payload}).then((resp) => {
                    resolve()
                }
            ).catch((err) => {
                console.log(err);
                reject(err.response.data.errors)
            })
        )
    },
    registerDriver({state, dispatch}, payload) {
        return dispatch('signUp', {endpoint: 'drivers', data: {driver: payload}})
    },
    registerSponsor({state, dispatch}, payload) {
        return dispatch('signUp', {endpoint: 'supporters', data: {supporter: payload}})
    },
    signUp({state}, payload) {
        return new Promise((resolve, reject) =>
            axios.post('/api/' + payload.endpoint, payload.data).then((resp) => {
                    state.user = resp.data;
                    resolve()
                }
            ).catch((err) => {
                console.log(err);
                reject(err.response.data.errors)
            })
        )
    },
    signOut() {
        return new Promise((resolve, reject) =>
            axios.delete('/api/users/sign_out').then((resp) => {
                    resolve()
                }
            ).catch((err) => {
                reject(err.response.data)
            })
        )
    },
    login({commit}, data) {
        return new Promise((resolve, reject) =>
            axios.post('/api/users/sign_in', {user: data})
                .then((resp) => {
                    commit('setUser', resp.data);
                    resolve(resp.data)
                })
                .catch((err) => {
                    reject(err.response.data)
                })
        )
    }
};

export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
}
