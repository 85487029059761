export const modalLogic = {
  data() {
    return {
      overlayHidden: false,
      containerHidden: true,
      startX: 0,
      startY: 0,
    }
  },
  created() {
    const escapeHandler = (e) => {
      if (e.key === 'Escape' && e.keyCode == 27) {
        this.closePopup();
      }
    }
    document.addEventListener('keydown', escapeHandler);
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escapeHandler);
    });
  },
  mounted() {
    this.containerHidden = false
  },

  methods: {
    onTouchStart(event) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    },
    onTouchMove(event) {
      const deltaY = event.touches[0].clientY - this.startY;
      if (deltaY > 20) {
        this.closePopup()
      }
    },
    hideOverlay() {
      this.overlayHidden = true
    },
    hideContainer() {
      this.containerHidden = true
    },
    closePopup() {
      this.hideContainer()
      this.hideOverlay()
      setTimeout(() => {
        this.$emit('popup-close')
      }, 350)
    },
  }
}
