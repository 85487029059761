<template>
  <div :class="[
    'setup-banner',
    { 'is-active': !chargesEnabled || !payoutsEnabled },
    { 'payouts': payoutsEnabled },
    { 'charges': chargesEnabled },
  ]">
    <div class="setup-banner__container">
      <template v-if="payoutsApproved">
      <p v-if="chargesEnabled" class="setup-banner__text">
        Good news! Charges are enabled now. Please head over to your Stripe account to set it up.
      </p>
      <p v-else-if="payoutsEnabled" class="setup-banner__text">
        Good news! Payouts are enabled now. Please head over to your Stripe account to set it up.
      </p>
      <p v-else class="setup-banner__text">
        We use Stripe, the leading provider of secured payments, to ensure you get paid on time. While your Stripe
        account is not set up OpenFender will handle your transaction. For payouts,
        <router-link to="/edit-profile/payment_info">please connect Stripe</router-link>
      </p>
      </template>
      <p v-else class="setup-banner__text">
        Your account is pending confirmation. Once approved, you’ll receive an email to connect Stripe for payouts. Until then, OpenFender will handle your transactions.
      </p>
      <div class="secure">
        <p class="secure__text">Secured by</p>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapGetters} from 'vuex';

export default {
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['chargesEnabled', 'payoutsEnabled', 'detailsSubmitted', 'payoutsApproved']),
  },
}
</script>
<style lang="sass" scoped>
.setup-banner
  position: relative
  z-index: 1
  width: 100%
  height: 0
  display: flex
  align-items: center
  justify-content: center
  color: #121212
  background: #FAC710
  overflow: hidden
  transition: all .3s ease-in-out

  &.is-active
    height: auto

  &.payouts
    background: #56D4F0

  &.charges
    background: #56F065

  &__container
    position: relative
    padding: 10px
    max-width: 1220px
    width: 100%

  &__title
    margin: 0

    font-size: 18px
    font-weight: 700

  &__text
    clear: both
    margin: 0
    display: inline

    font-size: 16px
    font-weight: 500
    color: #121212

    a
      font-weight: 700
      color: #F33E31
      text-decoration: underline

.secure
  float: right
  display: inline-flex
  margin-top: 0

  &__text
    display: inline-flex
    align-items: center
    gap: 4px
    font-family: 'Roboto', sans-serif
    font-weight: 400
    font-size: 14px
    white-space: nowrap
    // line-height: 22px
    &::before
      content: ''
      width: 24px
      height: 24px
      display: inline-block
      background-image: url('./lock.svg')

    &::after
      content: ''
      width: 41px
      height: 18px
      display: inline-block
      background-image: url('./stripe-white.svg')
</style>

