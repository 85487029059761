<template>
  <footer class="footer custom-footer">
    <div class="container">
      <div class="top">
        <ul class="top__links">
          <li class="top__link" style="grid-area: racers"><a href="/racers">Racers</a></li>
          <li class="top__link" style="grid-area: sponsors"><a href="/sponsors">Sponsors</a></li>
          <li class="top__link" style="grid-area: how-to"><a href="/how-to">Becoming a sponsor</a></li>
          <li class="top__link" style="grid-area: fans"><a href="/fans">Fans</a></li>

          <li class="top__link" style="grid-area: opportunitios"><a href="/sponsorship">SPONSORSHIP OPPORTUNITIES</a></li>
          <li class="top__link" style="grid-area: campaigns"><a href="/campaigns">SPECIFIC SPONSORSHIP</a></li>
          <li class="top__link" style="grid-area: fundraisings"><a href="/fundraisings">OPEN SPONSORSHIP</a></li>
          <li class="top__link" style="grid-area: boost"><a href="/drivers">BOOST</a></li>

          <li class="top__link" style="grid-area: about"><a href="/about-us">About us</a></li>
          <li class="top__link" style="grid-area: stories"><a href="/success-story">Success stories</a></li>
          <li class="top__link" style="grid-area: contact"><a href="mailto:racers@openfender.com">Contact us</a></li>
          <li class="top__link" style="grid-area: blog"><a href="/blog">Blog</a></li>
          <li class="top__link" style="grid-area: faq"><a href="/faq">FAQ</a></li>
        </ul>

        <div class="social">
          <p class="social__call">Join OpenFender community</p>
          <ul class="social__links">
            <li>
              <a
                href="https://www.facebook.com/OpenFender"
                class="social__link"
                target="_blank"
                :style="{ backgroundImage: 'url(' + socials.fb + ')' }"
              >
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/openfender"
                class="social__link"
                target="_blank"
                :style="{ backgroundImage: 'url(' + socials.t + ')' }"
              >
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/openfender"
                class="social__link"
                target="_blank"
                :style="{ backgroundImage: 'url(' + socials.inst + ')' }"
              >
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UClvKHqVknRk-VlXJx0w4ZyQ"
                class="social__link"
                target="_blank"
                :style="{ backgroundImage: 'url(' + socials.y + ')' }"
              >
              </a>
            </li>
          </ul>
          <a v-if="!loggedIn" href="/signup/racer" class="button is-primary is-small">Join now</a>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom__left">
          <a href="/terms" :target="target">Terms of use</a>
          <a href="/privacy" :target="target">Privacy policy</a>
        </div>

        <div class="bottom__right">
          <span>{{ `© ${new Date().getFullYear()}, OpenFender` }}</span>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import fb from './imgs/fb.svg'
import inst from './imgs/inst.svg'
import t from './imgs/t.svg'
import y from './imgs/y.svg'

export default {
  props: {
    target: {
      type: String
    },
  },
  data () {
    return {
      loggedIn: false,
      socials: {
        fb,
        inst,
        t,
        y
      }
    }
  }
}
</script>
<style lang="sass" scoped>

  .footer
    margin-top: 0px
    background: #282727
    padding: 60px 0 0 0
    position: relative
    @media screen and (max-width: 767px)
      padding-top: 32px

    .container
      padding: 0 20px
      max-width: 1220px
      display: flex
      flex-direction: column
      gap: 60px
      @media screen and (max-width: 1023px)
        gap: 70px
      @media screen and (max-width: 767px)
        gap: 32px

    a
      color: #fff
      transition: all .2s ease-in
      &:hover
        opacity: .7

  .top
    display: flex
    justify-content: space-between
    align-items: first baseline
    gap: 20px
    @media screen and (max-width: 1023px)
      flex-direction: column
      gap: 40px

    &__links
      width: 100%
      display: grid
      gap: 24px 40px
      grid-template-columns: repeat(3, 1fr)
      grid-template-areas: "racers opportunitios about" "sponsors campaigns stories" "how-to fundraisings contact" "fans boost blog" "faq . ."
      @media screen and (max-width: 767px)
        grid-template-columns: repeat(2, 1fr)
        grid-template-areas: "racers opportunitios" "sponsors campaigns" "how-to fundraisings" "fans stories" "about boost"  "contact blog" ". faq"

    &__link
      font-family: 'Bebas Neue', sans-serif
      font-size: 24px
      line-height: 24px
      text-transform: uppercase
      @media screen and (max-width: 767px)
        font-size: 18px

    a.button
      width: 100px

    .social
      display: flex
      flex-direction: column
      row-gap: 16px
      &__call
        font-family: 'Bebas Neue', sans-serif
        font-size: 24px
        line-height: 24px
        text-transform: uppercase
        white-space: nowrap
        @media screen and (max-width: 767px)
          font-size: 18px

      &__links
        display: flex
        gap: 20px

      &__link
        width: 22px
        height: 22px
        display: inline-block
        background-size: contain
        background-position: center
        background-repeat: no-repeat

  .bottom
    padding: 20px 0
    display: flex
    align-items: center
    justify-content: space-between
    border-top: 1px solid rgba(255, 255, 255, 0.05)
    @media screen and (max-width: 767px)
      align-items: first baseline

    &__left
      display: flex
      align-items: center
      gap: 16px 70px
      @media screen and (max-width: 767px)
        flex-direction: column

    &__right

    a, span
      font-size: 12px
      font-weight: 400
      letter-spacing: 0px
      line-height: 16px
</style>
