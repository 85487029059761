import axios from 'plugins/axios-instance'
import professional_profile from "../profile/professional_profile";

const state = {
    profile: {},
    embedProfile: {},
    embedProfileLoading: true,
    embedProfileErrors: false,
    cars: [],
    stripeCards: [],
    stripeAccount: {},
    stripeCustomer: {},
    isLightIframeTheme: false,
    profileLoaded: false,
    isDriverOn: null,
    isProfessionalOn: null,
}
const getters = {
    chargesEnabled(state) {
        return state.profile.charges_enabled
    },
    payoutsEnabled(state) {
        return state.profile.payouts_enabled
    },
    detailsSubmitted(state) {
        return state.profile.details_submitted
    },
    payoutsApproved(state){
        return state.profile.payouts_approved
    },

};
const mutations = {
    setProfile(state, data) {
        state.profileLoaded = true
        state.profile = data
    },
    setCars(state, data) {
        state.cars = data
    },
    setStripeCustomer(state, data) {
        state.stripeCustomer = data.stripe_customer;
        if (data.payment_methods)
            state.stripeCards = data.payment_methods
    },
    setStripeAccount(state, data) {
        state.stripeCustomer = data
    },
    setTheme(state, data) {
        state.isLightIframeTheme = data
    },
    setEmbedProfile(state, data) {
        state.embedProfile = data
    },
    setEmbedProfileLoading(state, data) {
        state.embedProfileLoading = data;
    },
    setEmbedProfileErrors(state, data) {
        state.embedProfileErrors = data;
    },
    SET_PROFESSIONAL_PROFILE(state, {professional_profile}) {
        state.profile = {...state.profile, ...professional_profile}
    },
    setIsDriverOn(state, data) {
        state.isDriverOn = data
    },
    setIsProfessionalOn(state, data) {
        state.isProfessionalOn = data
    },

};
const actions = {
    async updateProfessionalProfile({commit}, professional_profile) {
        const {data} = await axios.put('/driver/professional_profile', professional_profile)
        commit('SET_PROFESSIONAL_PROFILE', data)
    },
    getStripeCustomer({commit}) {
        axios.get('/sponsor/billing').then((resp) => {
                commit('setStripeCustomer', resp.data)
            }
        )
    },
    addCard({commit}, token) {
        axios.post('/sponsor/billing/cards', {token: token}).then((resp) => {
                commit('setStripeCustomer', resp.data.stripe_customer)
            }
        )
    },
    deleteCard({commit}, id) {
        axios.delete('/sponsor/billing/cards/' + id).then((resp) => {
                commit('setStripeCustomer', resp.data.stripe_customer)
            }
        )
    },
    getStripeAccount({commit}) {
        axios.get('/driver/billing').then((resp) => {
                commit('setStripeAccount', resp.data.stripe_account)
            }
        )
    },
    loadProfile({state, commit}) {
        axios.get('/profile').then((resp) => {
            commit('setProfile', resp.data)
        })
    },
    loadCars({commit}) {
        axios.get('driver/cars').then((resp) => {
                commit('setCars', resp.data)
            }
        )
    },
    deleteCar({commit}, id) {
        axios.delete('driver/cars/' + id).then((resp) => {
                commit('setCars', resp.data)
            }
        )
    },
    saveCar({commit}, car) {
        let method = car.id ? 'put' : 'post'
        let endpoint = car.id ? 'driver/cars/' + car.id : 'driver/cars'
        return new Promise((resolve, reject) =>
            axios({
                method: method,
                url: endpoint,
                data: car
            }).then((resp) => {
                    commit('setCars', resp.data)
                    resolve(resp)
                }
            ).catch((err) =>
                reject(err.response.data.errors)
            )
        )
    },
    getEmbedProfile({commit}, url) {
        commit('setEmbedProfileErrors', false);
        return axios.get(url, {
            headers: {
                'X-Frame-Options': 'allow-all'
            }
        })
            .then((resp) => {
                commit('setEmbedProfile', resp.data);
                commit('setEmbedProfileLoading', false);
            })
            .catch((error) => {
                console.error("Failed to fetch embed profile:", error);

                if (error.response && error.response.status === 404) {
                    commit('setEmbedProfileErrors', true);
                }

                commit('setEmbedProfileLoading', false);
            });
    }
};


export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
}
