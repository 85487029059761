<template>
    <div class="i">
        <label>
            <div :class="['columns', {'is-gapless': isMobile}, {'is-vcentered': isVcentered}]">
                <div  v-if="hasLabel" :class="['column', `is-${labelColumnWidth}`, 'i__label']">
                    <slot/>
                </div>
                <div :class="['column', `is-${columnWidth}`, 'control', customClass]">
                    <div class="control__wrapper">
                    <div class="control__icon" v-if="icon">
                        <div class="control__icon-wrapper control__icon-wrapper_empty" v-if="icon == 'empty'"/>
                        <img v-else :src="icon">
                    </div>
                    <textarea v-if="multiline"
                              :class="['textarea', {'is-danger':invalid}]"
                              :value="value"
                              :maxLength="maxLength || 5000"
                              @input="update"
                              @focus="showTooltip = true"
                              @blur="showTooltip = false"
                              :placeholder="placeholder">
                    </textarea>

                    <input v-else class="input"
                           :class="{'is-danger':invalid}"
                           :style="computedInputPadding"
                           :placeholder="placeholder"
                           :value="value"
                           :type="type || 'text'"
                           :min="min"
                           :disabled="disabled"
                           :readonly="readonly"
                           ref="control"
                           @focus="showTooltip = true"
                           @blur="showTooltip = false"
                           @input="update"/>
                    </div>
                    <p class="help is-danger" :style="computedHelpHeight" v-if="invalid">
                        <slot name="error"></slot>
                    </p>
                </div>
                <div class="column third-column is-3" v-if="hasTooltipSlot">
                    <div class="height-wrapper">
                        <div class="tooltip" :class="{ 'visible': showTooltip }">
                            <slot name="tooltip"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </label>

    </div>
</template>

<script>
    import { windowResize } from '../mixins/window_resize'

    export default {
        mixins: [ windowResize ],
        props: {
            label: null,
            value: null,
            type: '',
            placeholder: '',
            multiline: false,
            invalid: false,
            icon: '',
            min: null,
            maxLength: null,
            disabled: false,
            readonly: false,
            isVcentered: false,
            customClass: '',  
            columnWidth: {
                type: Number,
                default: 8
            },
            labelColumnWidth: {
                type: Number,
                default: 4
            },
            hasLabel: {
                type: Boolean,
                default: true
            },
            helpHasHeight: {
                type: Boolean,
                default: false
            }    
        },

        data() {
            return {
                showTooltip: false,
            }
        },
        methods: {
            update(e) {
                this.$emit('input', e.target.value)
            }
        },
        computed: {
            hasTooltipSlot () {
                return !!this.$slots['tooltip'] || !!this.$scopedSlots['tooltip']
            },
            computedInputPadding() {
                return this.icon && this.icon !== 'empty' ? 'padding-left: 48px' : ''
            },
            computedHelpHeight() {
                return this.helpHasHeight ? 'height: 14px' : 'height: 0'
            }
        },
    }
</script>
<style lang="sass" scoped>
    .input::placeholder, .textarea::placeholder
        color: rgba(255, 255, 255, .5)

    .input[readonly]
        color: rgba(255, 255, 255, .5)
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden

        &:hover, &:focus
            background-color: #ffffff07
            border-color: rgba(255, 255, 255, 0.2) !important

    .i
        margin-bottom: 30px
        &__label
            @media screen and (max-width: 768px) 
                max-height:  38px
                font-size: 14px
                line-height: 28px

        label
            font-size: 16px
            font-weight: 400
            line-height: 24px
        p.help
            // height: 0
            font-weight: normal
            font-size: .75rem
            margin: 0
            line-height: 20px
        .control
            &__wrapper
                position: relative
                display: flex
                align-items: center
            &__icon
                &-wrapper

                    @media (max-width: 768px)
                        margin-left: 0
                        margin-right: 12px

                    &_empty
                        margin-right: 0
                        @media (max-width: 768px)
                            margin-right: 0
                img
                    position: absolute
                    top: 50%
                    left: 10px
                    transform: translateY(-50%)
                    display: block
                    width: 23px
                    height: 23px


    .third-column

        @media (max-width: 768px)
            margin-bottom: -32px

    .height-wrapper
        max-height: 44px


        @media (max-width: 768px)
            height: 0px

    .is-danger.input, .taginput .is-danger.taginput-container.is-focusable, .is-danger.textarea
        border-color: #da362a
    .help.is-danger
        color: #da362a

    .tooltip
        background: rgb(255, 255, 255)
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12)
        border-radius: 2px
        padding: 14px 20px 18px
        color: rgb(48, 48, 54)
        font-size: 12px

        font-weight: normal
        letter-spacing: 0px
        line-height: 20.4px
        opacity: 0
        transition: opacity 0.3s ease
        pointer-events: none


        @media (max-width: 768px)
            z-index: 99
            position: relative


        &.visible
            opacity: 1
            display: block

        h3
            font-size: 16px

            font-weight: bold
            line-height: 24.6px
            margin-bottom: 7.4px

</style>