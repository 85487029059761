export default [
    {
        path: '/login/f2685563e3f97c1423fdef564196abcc629c95a554f1e80b834cc3d7129f1297ab2f7fac6206b76d3cd0e539acae1a150d67bd7933c76a341ece6b9a6a808d74',
        async beforeEnter(to, from, next) {
            console.log(to.query.token)
            await store.dispatch('auth/impersonate', to.query)
            next({name: 'home'})
        }
    },

    {
        path: '/login',
        component: () => import(/* webpackChunkName: "sign" */ "signup/login")
    },
    {
        path: '/signup',
        component: () => import(/* webpackChunkName: "sign" */ "signup"),
        children: [
            {
                path: 'racer',
                component: () => import(/* webpackChunkName: "sign" */ "signup/driver"),
                name: 'racer'
            },
            {
                path: 'sponsor',
                component: () => import(/* webpackChunkName: "sign" */ "signup/sponsor"),
                name: 'sponsor'
            },
            {
                path: 'facebook',
                component: () => import(/* webpackChunkName: "sign" */ "signup/facebook"),
                name: 'facebook'
            },
            {
                path: 'forgot-password',
                component: () => import(/* webpackChunkName: "sign" */ "signup/forgot-password"),
                name: 'forgot_password'
            },
            {path: 'password_reset', component: () => import(/* webpackChunkName: "sign" */ "signup/reset")},
            {path: '/', redirect: 'driver'},
            {path: '*', redirect: 'driver'}
        ]
    },
]