<template>
    <div class="app-main">
        <Navbar v-if="isShowNavbar" :user="user" @log-out="logOut" />
        <router-view></router-view>
        <Footer v-if="isShowFooter" />
    </div>
</template>

<script>
    import _ from 'underscore'
    import Navbar from './shared_components/navbar.vue'
    import Footer from 'landing_components/footer'

    import {mapState, mapActions} from 'vuex'
    export default {
        data: function () {
            return {
                trunk_parts: [1, 2],
                roof_parts: [3, 4, 5, 6],
                hood_parts: [7, 8, 9, 10],
                parts: [],
                slots: [],
                slot: {
                    name: '',
                    notes: '',
                    price: null,
                    parts: []
                },
            }
        },
        components: { Navbar, Footer },
        computed: {
            ...mapState('auth', ['user']),
            uniq() {
                for (let i = 0; i < this.slots.length; i++) {
                    let s = this.slots[i];
                    if (s.parts.length == this.slot.parts.length)
                        if (_.difference(this.slot.parts, s.parts).length == 0)
                            return false
                }
                return true
            },
            isShowFooter(){
              return !this.$route.path.includes("builder") && !this.$route.path.includes("embed")
            },
            isShowNavbar(){
              return !this.$route.path.includes("embed")
            }
        },
        methods: {
            ...mapActions('auth', ['signOut']),
            logOut(){
                if (localStorage.fundchaserUrl) localStorage.removeItem('fundchaserUrl')
                if (localStorage.source) localStorage.removeItem('source')
                this.signOut().then(()=>{
                    window.location = '/'
                })
            },
            deleteSlot(idx) {
                this.slots.splice(idx, 1)

            }
            ,
            selectSlot(idx) {
                this.slot = this.slots[idx]
            }
            ,
            save() {
                this.slot = {parts: []}
            }
            ,
            addSlot() {
                this.slot.id = 1;
                this.slots.push(this.slot);
                this.slot = {parts: []}
            }
            ,
            toggle(part) {
                if (this.slot.id)
                    return false
                this.slot.parts = [part]
            }
        }
    }
</script>

<style lang="sass" scoped>
    .app-main
        display: flex
        flex-direction: column
        min-height: 100%

    #app
        .slot
            border: 1px solid navy
        .error
            color: red
            font-weight: bold
        .car
            background-color: #ffecea
            background-image: url('images/car.png')
            background-size: contain
            background-repeat: no-repeat
            width: 400px
            height: 200px
            display: flex

            .parts
                flex: auto
                margin: 10px
                border: 2px solid #0053ff
                display: flex

                .part
                    color: white
                    cursor: pointer
                    margin: 2px
                    border: 1px dotted #faf500
                    display: flex
                    justify-content: center
                    align-items: center
                    &.selected
                        background: rgba(250, 245, 0, 0.51)

                &-four
                    flex-wrap: wrap

                    .part
                        flex: 0 0 45%

                &-two
                    flex-wrap: wrap

                    .part
                        flex: 0 0 95%


            .trunk, .roof, .bonnet
                flex: auto
                display: flex

            .trunk
                flex: 0 0 60px

            .bonnet
                flex: 0 0 130px


            .spacer
                width: 30px

</style>

<style lang="scss">
html, body {
    height: 100%;
}

.datepicker-header{
       select{
        color: black !important;
  }
}

.cards-modal .modal-close {
    position: absolute;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    &:before {
        width: 55%;
    }
    &:after {
        height: 55%;
    }
    &:before, &:after {
        border-radius: 4px;
        background-color: #5c5c5c;
    }
    &:hover {
        transform: rotate(180deg);
    }
}

.pac-target-input::placeholder {
    color: rgba(255, 255, 255, .5)
}

@media screen and (max-width: 450px) {
    .cards-modal .modal-close {
      display: none;
    }
}
</style>
