import axios from 'plugins/axios-instance'

export default {
    namespaced: true,
    state: {
        fundraising: {},
        fundraisings: []
    },
    getters:{
        drafts(state){
            return state.fundraisings.filter(f => f.aasm_state == 'draft')
        },
        active(state){
            return state.fundraisings.filter(f => f.aasm_state == 'active')
        },
        finished(state){
            return state.fundraisings.filter(f => f.aasm_state == 'finished')
        }
    },
    actions: {
        async sendToDraft({state, dispatch}, id){
            await axios.put(`/driver/fundraisings/${id}/draft`)
            dispatch('load')
        },
        async load({state}) {
            const resp = await axios.get('/driver/fundraisings')
            state.fundraisings = resp.data.fundraisings
        },
        async loadOne({state}, id) {
            const resp = await axios.get(`/public/fundstandings/${id}`)
            state.fundraising = resp.data.fundraising
        },

    }
}