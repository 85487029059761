import axios from 'plugins/axios-instance'

const state = {
  purchaseData: {}
};

const mutations = {
  setPurchaseData(state, data) {
    state.purchaseData = data
  }
};

const actions = {
  async getPurchaseData({commit}, id) {
    let resp = await axios.get('/public/stripe/' + id)
    commit('setPurchaseData', resp.data)
  },
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
};