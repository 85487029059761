<template>
  <div class="profile">
    <div class="columns profile__wrapper">
      <div
        :class="[
          'column',
          'is-12',
          'profile__main-content',
          isMobile ? 'is-gapless' : ''
        ]"
      >
        <form @submit="onSubmitProfile" @reset="onReset">
          <div class="columns profile__heading profile__heading_switcher">
            <div class="column is-4">
              <h2 class="profile__title">Professional profile</h2>
              <p class="profile__subtitile">Tell your audience about your professional industry.</p>
            </div>
            <div class="column is-6 switch-box">
              <b-tooltip 
                :active="!isDriverOn"
                label="Both profiles cannot be disabled"
                type="is-dark"
                position="is-top">
                <b-switch 
                  v-model="professionalProfile.enabled"
                  @input="showDisablingModal"
                  type="is-danger"
                  :disabled="!isDriverOn || isEmptyProfile"
                  class="profile__professional-switcher"
                >
                </b-switch>
              </b-tooltip>
              <transition-group name="fade" mode="out-in">
                <p v-if="!isProfessionalOn && !isEmptyProfile" class="profile__disable-info" key="disabled">
                  Your profile is disabled. Turn it on so your audience can see your profile.
                </p>
                <div v-if="isEmptyProfile" class="profile__disable-info profile__disable-info_init" key="empty">
                  <p :class="{'note-filled': !emptyProfile}">
                    <strong>Please note</strong>:
                    to enable the professional profile, you need to fill in the following fields:
                  </p>
                  <ol :style="`opacity: ${emptyProfile ? 1 : .4}`">
                    <li 
                      :class="{'profile__note_filled': professionalProfile.categories && professionalProfile.categories.length}"
                    >
                      Category
                    </li>
                    <li 
                      :class="{'profile__note_filled': professionalProfile.email && professionalProfile.phone}"  
                    >
                      Contact information
                    </li>
                  </ol>
                  <p v-if="!emptyProfile">Now <strong>SAVE</strong> your profile.</p>
                </div>
              </transition-group>
            </div>
          </div>

          <div class="columns is-gapless">
            <div class="column">
              <TextInput
                :value="`${isProfessionalOn ? 'https://openfender.com' + professionalUrl : ''}`"
                :columnWidth="6"
                readonly
                :style="`${!isProfessionalOn ? 'pointer-events: none;' : ''}`"
              >
                URL
              </TextInput>
              <div :class="['column', 'is-6', 'is-offset-4', 'pb-0', `${isMobile ? 'pl-0' : ''}`]">
                <div 
                  :class="[
                    'profile__link', 
                    {'disabled': !isProfessionalOn}
                  ]"
                >
                  <a :href="isProfessionalOn ? professionalUrl : '#'" target="_blank">
                    Open your professional profile
                  </a>
                  <b-icon
                    icon="open-in-new"
                    size="is-small">
                  </b-icon>
                </div>
              </div>
            </div>
          </div>

          <div class="columns is-gapless">
            <div class="column">
              <Multiselect 
                v-model="$v.professionalProfile.categories.$model"
                :invalid="$v.professionalProfile.categories.$error"
                :options="professional_category"
                :columnWidth="6"
                placeholder="Select category"
              >
                Category
                <template slot="error" v-if="$v.professionalProfile.categories.$error">
                  Please select at least one category
                </template>
              </Multiselect>
            </div>
          </div>

          <div class="columns">
            <div class="column is-4 i__label">
              <label>Portfolio</label>
            </div>
            <div class="column is-6">
              <PhotoUploader
                  :photos="images"
                  :oversize="portfolioImageOversize"
                  :loading="uploadingPortfolioImage"
                  @onPhotoUpload="uploadPortfolioImage"
                  @onPhotoDelete="deletePortfolioImage"
                  @clearOversize="portfolioImageOversize = false">
              </PhotoUploader>
            </div>
          </div>

          <TextInput
            v-model="professionalProfile.about"
            :multiline="true"
            :columnWidth="6"
            class="mb-3"
          >
            <p class="input__label">About me</p>
            <p class="input__sublabel desktop">
              Tell us about yourself. We don't need your life story — just some background on who you are.
            </p>
          </TextInput>

          <TextInput
            v-model="professionalProfile.services"
            :multiline="true"
            :columnWidth="6"
          >
            <p class="input__label">Services</p>
            <p class="input__sublabel desktop">
              Tell everyone what services you provide.
            </p>
          </TextInput>

          <div class="columns m-0">
            <div class="column is-10 p-0">
              <hr class="divider">
            </div>
          </div>

          <div class="column is-5 p-0 profile__heading">
            <h2 class="profile__title">Contact info</h2>
            <p class="profile__subtitile">
              Add contact information to contact you. Your contact information will be visible to all OpenFender users.
            </p>
          </div>

          <TextInput
            v-model="$v.professionalProfile.email.$model"
            :invalid="$v.professionalProfile.email.$error"
            :columnWidth="6"
            type="email"
            placeholder="name@example.com"
            @input="cleanError('email')"
            customClass="profile__email mb-3"
            ref="emailInput"
          >
            Contact email
            <template slot="error" v-if="!$v.professionalProfile.email.email">
              Please check your email
            </template>
            <template slot="error" v-if="!$v.professionalProfile.email.required">
              Email can't be blank
            </template>
          </TextInput>

          <TextInput
            v-model="$v.professionalProfile.phone.$model"
            :invalid="$v.professionalProfile.phone.$error"
            :columnWidth="6"
            type="tel"
            placeholder="+1 (555) 000-0000"
            ref="phoneInput"
          >
            Phone number
            <template slot="error" v-if="!$v.professionalProfile.phone.required">
              Phone can't be blank
            </template>
          </TextInput>

          <div class="columns m-0">
            <div class="column is-10 p-0">
              <hr class="divider">
            </div>
          </div>

          <div 
            class="columns profile__controls-wrapper" 
            ref="controlsWrapper">
            <div class="column profile__controls">
              <button
                type="submit"
                class="button is-primary is-large save-btn"
                :disabled="updating"
              >
                <template v-if="updating"> Saving... </template>
                <template v-else>Save</template>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <DisablingModal 
      v-if="isDisablingModalVisible" 
      @popup-close="onCancel"
      @confirm="onConfirm"
      @cancel="onCancel"
      :status="professionalProfile.enabled"
      :loading="updating"
    />
  </div>
</template>
<script>
import _ from "underscore";
import {mapState, mapActions, mapMutations} from "vuex";
import { required, email } from "vuelidate/lib/validators";

import TextInput from "components/text_input";
import PhotoUploader from "shared_components/photo_uploader"
import Multiselect from "components/multiselect/index.vue";
import DisablingModal from "./disabling_modal.vue"

import { CATEGORIES } from 'libs/professional_category'
import { windowResize } from '../mixins/window_resize'

export default {
  mixins: [ windowResize ],
  components: {
    TextInput,
    PhotoUploader,
    Multiselect,
    DisablingModal
  },
  watch: {
    profileLoaded:{
      handler(val){
        if(val) {
          this.reset()
          this.professionalProfile.enabled = this.isProfessionalOn
          this.images = this.profile.professional_profile.professional_profile_images
          this.isEmptyProfile = !(this.profile.professional_profile.categories.length && this.profile.professional_profile.phone && this.profile.professional_profile.email)
        }
      },
      immediate: true
    },
    isMobile(val) {
      !val ? this.removeControlsOverlay() : this.addControlsOverlay()
    },
    'professionalProfile.phone'(newValue) {
      if (!newValue) return
      const cleanedInput = newValue.replace(/[^\d+\-() ]/g, '').replace(/\s{2,}/g, ' ').trim()

      if (cleanedInput === '') {
        this.professionalProfile.phone = ''
      } else {
        this.professionalProfile.phone = cleanedInput.startsWith('+') 
          ? cleanedInput 
          : `+${cleanedInput}`
      }
    },
  },
  data() {
    return {
      updating: false,
      uploading: false,
      images: [],
      professionalProfile:{},
      category: '',
      errors: {},
      uploadQueue: [],
      photos: [],
      fileSize: null,
      uploadingPortfolioImage: false,
      portfolioImageOversize: false,
      isDisablingModalVisible: false,
      controlsWrapper: null,
      isEmptyProfile: false
    };
  },

  mounted() {
    this.controlsWrapper = this.$refs.controlsWrapper;
    if (this.isMobile) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },

  beforeDestroy() {
    if (this.isMobile) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },

  computed: {
    ...mapState('profile', ['profile', 'profileLoaded', 'isProfessionalOn', 'isDriverOn']),

    professional_category() {
      return CATEGORIES
    },
    professionalUrl() {
      return `/vendors/${this.profile.friendly_id}`;
    },
    maxDobDate() {
      return new Date();
    },
    emptyProfile() {
      return !!!((this.professionalProfile.categories && this.professionalProfile.categories.length) 
        && this.professionalProfile.email 
        && this.professionalProfile.phone)
    }
  },

  validations: {
    professionalProfile: {
      email: {
        required,
        email
      },
      phone: {
        required
      },
      categories: {
        required
      },
    }
  },
  methods: {
    ...mapActions("profile", ['updateProfessionalProfile', 'toggleProfessional']),
    ...mapMutations("profile", ["setIsProfessionalOn"]),
    async deletePortfolioImage(index) {
      const photo = this.images[index];
      if (photo) {
        try {
          await this.axios.delete("driver/professional_profile/images/" + photo.id);
          this.images.splice(index, 1);
        } catch (error) {
          console.error("Error deleting image:", error);
        }
      }
    },
    uploadPortfolioImage(event) {
      this.uploadingPortfolioImage = true;
      const file = event.target.files[0];
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

      if (!allowedTypes.includes(file.type)) {
        this.uploadingPortfolioImage = false;
        console.log('Only images are allowed (JPEG, PNG, GIF)')
        return;
      }

      this.fileSize = file.size / 1000 / 1000;
      if (this.fileSize > 10) {
        this.uploadingPortfolioImage = false;
        this.portfolioImageOversize = true;
        return;
      }

      const data = new FormData();
      data.append('file', file);

      const fileInput = event.target;
      fileInput.value = '';

      this.axios.post('driver/professional_profile/images/', data)
        .then(resp => {
          this.images.push(resp.data);
          this.uploadingPortfolioImage = false;
        })
        .catch(error => {
          console.error('Error uploading image:', error);
          this.uploadingPortfolioImage = false;
        });
    },

    cleanError(field) {
      delete this.errors[field];
    },
    
    async onSubmitProfile(e) {
      e.preventDefault();
      this.updating = true;
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.scrollToInvalid()
        this.showErrorToast()
        this.updating = false;
        return;
      }
      await this.updateProfessionalProfile(this.professionalProfile)
      this.showSuccessToast()
      this.isEmptyProfile = false
      this.updating = false;
      return
    },
    onReset(e) {
      e.preventDefault();
      this.reset();
    },
    reset() {
      this.professionalProfile = {...this.profile.professional_profile}
    },
    showSuccessToast() {
      this.$buefy.toast.open({
        message: "Profile updated",
        type: "is-success",
        queue: false,
        duration: 1000
      });
    },
    showErrorToast() {
      this.$buefy.toast.open({
        message: "Please check your info",
        type: "is-danger",
        queue: false,
        duration: 2000
      });
      return;
    },
    
    scrollToInvalid() {
      const firstInvalidInput = document.querySelector('.help.is-danger');
      if (firstInvalidInput) {
        const parentNode = firstInvalidInput.parentNode;
        if (parentNode) {
          setTimeout(() => {
            const offsetY = 100
            const elementPosition = parentNode.getBoundingClientRect().top + window.pageYOffset

            window.scrollTo({
              top: elementPosition - offsetY,
              behavior: 'smooth'
            });
          }, 200);
        }
      }
    },
    toggleProfessionalProfile() {
      this.setIsProfessionalOn(this.professionalProfile.enabled);
    },
    showDisablingModal() {
      this.isDisablingModalVisible = true
    },
    hideDisablingModal() {
      this.isDisablingModalVisible = false
    },
    async onConfirm() {
      this.updating = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.onCancel()
        this.hideDisablingModal()
        this.scrollToInvalid()
        this.showErrorToast()
        this.updating = false
        return;
      }
      await this.updateProfessionalProfile(this.professionalProfile)
      this.toggleProfessionalProfile()
      this.showSuccessToast()
      this.updating = false
      this.hideDisablingModal()
    },  
    onCancel() {
      this.professionalProfile.enabled = !this.professionalProfile.enabled
      this.hideDisablingModal()
    },
    handleScroll() {
      const container = this.$el.parentElement;
      const containerHeight = container.offsetHeight;
      const controlsWrapperBottom = this.controlsWrapper.offsetTop + this.controlsWrapper.offsetHeight;
      const containerBottom = container.offsetTop + containerHeight;

      if (this.isMobile) {
        controlsWrapperBottom >= containerBottom 
          ? this.removeControlsOverlay() 
          : this.addControlsOverlay()
      }
    },
    addControlsOverlay() {
      this.controlsWrapper.style.boxShadow = '0 -10px 20px 0 rgba(0, 0, 0, 0.2)';
      this.controlsWrapper.style.backgroundColor = '#3e3e3e';
    },
    removeControlsOverlay() {
      this.controlsWrapper.style.boxShadow = 'none';
      this.controlsWrapper.style.backgroundColor = 'transparent';
    },
  }
};
</script>

<style lang="sass">
.profile
  .help:has(&__socials-error)
    display: block
    height: 8px !important

  .select:not(.is-multiple)
      height: 44px

  .i
      margin-bottom: 22px
      label
      line-height: 44px

  .b-tooltip
    .tooltip-content
      bottom: 50px 
</style>

<style lang="sass" scoped>
.divider
  width: 100%
  height: 1px
  background: #3E3E3E
  margin: 48px 0
  @media screen and (max-width: 768px)
    margin: 32px 0

.profile
  &__professional-switcher
    display: flex
    align-items: center
    gap: 12px
    &::before
      content: 'Disable'
    &::after
      content: 'Enable'

    & ~ p
      color: rgba(255, 255, 255, .6)
      font-size: 14px
      line-height: 20px


  &__heading
    margin-bottom: 48px
    @media screen and (max-width: 768px)
      margin-bottom: 24px
    &_team
      @media screen and (max-width: 768px)
        margin-top: 0
    .switch-box
      .profile__disable-info
        margin-top: 16px
        color: rgba(255, 255, 255, .8)
        font-size: 14px
        line-height: 20px
        p
          margin-bottom: 0
        strong
          color: rgba(255, 255, 255, .8)
        ol
          margin-left: 20px
          margin-top: 4px
          list-style-type: auto
          li
            transition: all .2s ease-in-out 
            &.profile__note_filled
              text-decoration: line-through
              color: rgba(255, 255, 255, .2)
        
        &_init
          font-size: 16px
          line-height: 24px
          p.note-filled
            color: rgba(255, 255, 255, .2)
            transition: color .2s ease-in-out
            strong
              color: rgba(255, 255, 255, .2)
              transition: color .2s ease-in-out

      @media screen and (max-width: 768px)
        padding-top: 8px !important
        padding-bottom: 20px !important

    &_switcher
      .column
        padding-bottom: 0
        @media screen and (max-width: 768px)
          padding-bottom: 12px

      @media screen and (max-width: 768px)
        margin-bottom: 0
        padding-top: 8px
        .profile__disable-info
          margin-top: 12px

  &__title
      font-size: 22px
      line-height: 26px
      color: #fff
  &__subtitile
    opacity: .8

  .input
    &__label
      margin: 0 0 4px
      line-height: 24px
      
    &__sublabel
      font-size: 14px
      line-height: 20px
      opacity: .6

  &__controls-wrapper
    position: sticky
    bottom: 24px
    transition: all .2s ease-in-out
    @media screen and (max-width: 768px)
      margin: -1rem
      bottom: 0
      z-index: 4

  &__controls
    display: flex
    flex-direction: row-reverse

  &__link
    display: flex
    gap: 4px
    font-size: 14px
    line-height: 20px
    transition: all .2s ease
    @media screen and (max-width: 768px)
      margin-top: 4px
    
    &.disabled
      cursor: not-allowed
      pointer-events: none
      opacity: .2
      transition: all .2s ease

    a
      color: rgba(255, 255, 255, .9)
      text-decoration: underline
      &:hover
        color: rgba(255, 255, 255, .7)
        transition: color .2s ease-in-out

    span
      margin-top: -2px
      display: inline-block
      opacity: .2

  .save-btn
    width: 15%
    min-width: 0
    @media screen and (max-width: 768px) 
      width: 100%

  .button
    @media screen and (max-width: 375px)
      width: 100%

  .i, .is-vcentered
    margin: 0

.desktop
    display: block
.mobile
    display: none

@media screen and (max-width: 768px)
    .profile
        &__wrapper
            display: flex
            flex-direction: column
        &__main-content
            order: 2
        &__descr-wrap
            order: 1
        &__datepicker
          &_icon
            right: 25px

    .desktop
        display: none
    .mobile
        display: block


.photos
  display: flex
  flex-wrap: wrap
  gap: 20px

  .caption
    display: flex
    flex: 1 1 100%
    font-size: 14px
    line-height: 20px
    color: rgba(255, 255, 255, .5)
</style>
