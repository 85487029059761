require('libs/fabric')
export default [
    {
        path: '/my-shop',
        component: () => import(/* webpackChunkName: "shop" */ "merch_shop"),
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "shop" */ "merch_shop/merch_shop"),
                name: 'shop'
            },
            {
                path: 'products',
                component: () => import(/* webpackChunkName: "shop" */ "merch_shop/products"),
                name: 'products'
            },
            {
                path: 'builder',
                component: () => import(/* webpackChunkName: "shop" */ "merch_shop/builder"),
                name: 'product_builder',
            },
        ]
    },
    {
        path: '/my-shop/products/:id',
        component: () => import(/* webpackChunkName: "shop" */ "merch_shop/product_page"),
        name: 'product_page'
    }
]