import axios from 'plugins/axios-instance'
import moment from 'moment'

const state = {
    id: null,
    vehicle: {},
    racer: {},
    info: {},
    slots: []
};
const getters = {
    defaultStartDate() {
        return moment().add(4, 'weeks').format()
    }
};
const mutations = {
    setVehicle(state, data) {
        state.vehicle = data
    },
    setInfo(state, data) {
        state.info = data
    },
    setSlots(state, data) {
        state.slots = data
    },
    setRacer(state, data) {
        state.racer = data;
    },
    setId(state, id) {
        state.id = id
    },
    backToCars(state) {
        state.vehicle = {
            vehicle_model: '',
            vehicle_brand: '',
            vehicle_type: '',
            vehicle_description: '',
            vehicle_images: []
        };
    },
    resetCampaignState(state, user) {
        state.vehicle = {
            vehicle_model: '',
            vehicle_brand: '',
            vehicle_type: '',
            vehicle_description: '',
            vehicle_images: []
        };
        state.info = {
            cover_photos: [],
            name: '',
            start_date: '',
            apply_before: ''
        };
        state.racer = {
            racer_avatars: [],
            racer_bio: user.bio,
            racer_hometown: user.hometown,
            racer_name: user.name,
            racer_social: user.social,
            racer_facebook_url: user.facebook_url,
            racer_instagram_url: user.instagram_url,
            racer_linkedin_url: user.linkedin_url,
            racer_youtube_url: user.youtube_url,
            racer_tiktok_url: user.tiktok_url,
            racer_snapchat_url: user.snapchat_url,
            racer_twitter_url: user.twitter_url,
            racer_other_url: user.other_url,
            team_name: user.team,
            team_url: user.team_url,
            racer_contact1: user.contact1,
            racer_contact2: user.contact2,
            racer_contact3: user.contact3,
        };
        state.slots = []
    }
};
const actions = {
    cloneCar({state, commit}, id) {
        axios.post('driver/campaigns/' + state.id + '/clone_car', {car_id: id}).then(resp => {
            commit('setVehicle', resp.data.vehicle)
        });

    },
    resetSlots({state, commit}) {
        commit('setSlots', []);
        axios.delete('driver/campaigns/' + state.id + '/slots/reset')
    },
    load({state}, id) {
        if (state.id) {

        } else {

        }

    }
};


export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
}
