const SCALE_FACTOR = 3
const LOCATIONS_USED = ['front', 'back', 'left_chest', 'right_chest', 'upper_back']
const LOCATIONS_FRONT = ['front', 'left_chest', 'right_chest']
const LOCATIONS_BACK = ['back', 'upper_back']

let artworkStore = {}
let previewStore = {}

function removeArtwork(id) {
    delete artworkStore[id]
}

async function storeArtwork({id, url}) {
    let img = await loadImage(url)
    artworkStore[id] = img
    return {
        originalWidth: img.width,
        originalHeight: img.height,
        aspectRatio: img.width / img.height,
        dataUrl: img.toDataURL()
    }
}

async function generateProductVariant(variant, printLocations, artworks) {
    let front = await loadImage(variant.images.front)
    let back = await loadImage(variant.images.back)
    let canvasEl = document.createElement('canvas')
    let frontCanvas = new fabric.StaticCanvas(canvasEl)
    let backCanvas = new fabric.StaticCanvas(canvasEl)
    frontCanvas.setDimensions({
        width: front.width,
        height: front.height
    })
    backCanvas.setDimensions({
        width: back.width,
        height: back.height
    })
    frontCanvas.add(front)
    backCanvas.add(back)
    for (let location of LOCATIONS_FRONT) {
        let printLocation = printLocations.find(p => p.id == location)
        if (printLocation) {
            let img = await loadImage(generatePrintLocation(artworks, printLocation, 1))
            img.set({
                left: printLocation.left,
                top: printLocation.top,
            })
            frontCanvas.add(img)
        }
    }
    for (let location of LOCATIONS_BACK) {
        let printLocation = printLocations.find(p => p.id == location)
        if (printLocation) {
            let img = await loadImage(generatePrintLocation(artworks, printLocation, 1))
            img.set({
                left: printLocation.left,
                top: printLocation.top,
            })
            backCanvas.add(img)
        }
    }
    return {
        ...variant,
        images: {
            front: frontCanvas.toDataURL({format: 'jpeg', quality: 0.8}),
            back: backCanvas.toDataURL({format: 'jpeg', quality: 0.8}),
        }
    }
}

function generatePrintLocation(artworks, printLocation, scaleFactor) {
    let canvasEl = document.createElement('canvas')
    let canvas = new fabric.StaticCanvas(canvasEl)
    canvas.setDimensions({
        width: printLocation.width * scaleFactor,
        height: printLocation.height * scaleFactor
    })

    let renderableArtworks = artworks.filter((a) => a.location == printLocation.id)
    if (renderableArtworks.length > 0) {
        renderableArtworks.map((a) => {
            let canvasImg = artworkStore[a.id]
            canvasImg.set({
                scaleX: a.size.width / canvasImg.width * scaleFactor,
                scaleY: a.size.height / canvasImg.height * scaleFactor,
                left: a.position.left * scaleFactor,
                top: a.position.top * scaleFactor,
                originX: 'left',
                originY: 'top'
            });
            canvas.add(canvasImg)
        })
        // previewStore[printLocation.id] = canvas
        return canvas.toDataURL({format: 'png'})
    } else
        return null
}

async function loadImage(url) {
    return new Promise((resolve, reject) => {
        fabric.Image.fromURL(url, (img) => {
            resolve(img)
        })
    })
}

function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var dw = new DataView(ab);
    for (var i = 0; i < byteString.length; i++) {
        dw.setUint8(i, byteString.charCodeAt(i));
    }

    return new Blob([ab], {type: mimeString});
}

export {
    generatePrintLocation, artworkStore, storeArtwork, removeArtwork, generateProductVariant,
    dataURItoBlob,
    LOCATIONS_USED, LOCATIONS_FRONT, LOCATIONS_BACK
}