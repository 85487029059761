const state = function () {
    return window.appConfig
}
const getters = {
    stripeConnectLink(state) {
        return "https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://victorelmov.tk/stripe_callback&client_id=" +
            state.stripeClientId +
            "&state=unic&ssuggested_capabilities[]=trasnsfers&suggestsed_capabilities[]=card_spayments\">"
    }
};
const mutations = {};
const actions = {};

export default {
    namespaced: true,
    state: state(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
