import VueRouter from 'vue-router'
import Vue from 'vue'
import 'assets/buefy'
import '@mdi/font/css/materialdesignicons.css'
import Buefy from 'buefy'
import Vuelidate from 'vuelidate'
import VueDisqus from 'vue-disqus'
import BootstrapVue from 'bootstrap-vue'
import Vuex from 'vuex'
import VueAxios from 'plugins/vue-axios'
import axios from 'plugins/axios-instance'
import VueGtag from 'vue-gtag'
import Store from 'stores/store'
import {VueMasonryPlugin} from 'vue-masonry';
import VueScrollTo from 'vue-scrollto'
import VueClipboard from 'vue-clipboard2'
import Filters from "../libs/filters";
// App
import App from '../app.vue'
import VueCurrencyFilter from 'libs/vue-currency-filter.modern'

//Styles
require('assets/css')

import MerchShopRoutes from './merch_shop'
import LoginRoutes from './login'

Vue.use(VueCurrencyFilter,
    {
        symbol: '$',
        thousandsSeparator: ',',
        fractionCount: 2,
        fractionSeparator: '.',
        symbolPosition: 'front',
        symbolSpacing: false,
        avoidEmptyDecimals: undefined,
    })

Vue.use(VueClipboard)
var SocialSharing = require('vue-social-sharing');

Vue.use(SocialSharing);
Vue.use(VueDisqus)
Vue.use(BootstrapVue);
Vue.use(Vuex);
Vue.use(Buefy);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VueMasonryPlugin);
Vue.use(VueScrollTo);
Vue.use(VueGtag, {
    config: {id: 'G-7YXFNJXHC3'}
})
Vue.use(Filters)
let store = new Vuex.Store(Store);
let router = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
    routes: [
        ...LoginRoutes,
        {
            path: '/partners',
            component: () => import(/* webpackChunkName: "partners" */ "partners"),
            children: [
                {path: '', component: () => import(/* webpackChunkName: "partners" */ "partners/partners")},
                {path: ':name', component: () => import(/* webpackChunkName: "partners" */ "partners/partner")},
            ]
        },
        {
            path: '/boost',
            component: () => import(/* webpackChunkName: "support" */ "support")
        },
        {
            path: '/home',
            component: () => import(/* webpackChunkName: "profile" */ "home"),
            name: 'home',
            children: [
                {
                    path: '',
                    component: () => import(/* webpackChunkName: "profile" */ "home/campaigns"),
                    name: 'my_campaigns'
                },
                {path: 'statistics', component: () => import(/* webpackChunkName: "profile" */ "campaigns/stats"), name: 'campaign_stats'},
                {path: 'drivers', component: () => import(/* webpackChunkName: "profile" */ "agent/drivers"), name: 'drivers'},
                {
                    path: 'fundraising', component: () => import(/* webpackChunkName: "profile" */ "fundraising"), name: 'fundraising',
                    async beforeEnter(from, to, next) {
                        await store.dispatch('fundraising/load')
                        next()
                    }
                },
            ]
        },
        {path: '/history', component: () => import(/* webpackChunkName: "profile" */ "profile/history"), name: 'history'},
        {
            path: '/edit-profile',
            component: () => import(/* webpackChunkName: "profile" */ "profile"),
            children: [
                {path: '/', component: () => import(/* webpackChunkName: "profile" */ "profile/profile"), name: 'profile'},
                {path: 'driver', component: () => import(/* webpackChunkName: "profile" */ "profile/driver_profile"), name: 'driver_profile'},
                {path: 'professional', component: () => import(/* webpackChunkName: "profile" */ "profile/professional_profile"), name: 'professional_profile'},
                {path: 'billing', component: () => import(/* webpackChunkName: "profile" */ "profile/billing"), name: 'billing'},
                {path: 'payment_info', component: () => import(/* webpackChunkName: "profile" */ "profile/payment"), name: 'payment_info'},
                {path: '*', redirect: {name: 'profile'}},
            ]
        },
        {path: '/campaigns/builder/:id?', component: () => import(/* webpackChunkName: "profile" */ "campaign_builder"), name: 'campaign_builder'},
        {path: '/fundraising/builder/:id?', component: () => import(/* webpackChunkName: "profile" */ "fundraising/builder"), name: 'fundraising_builder'},
        {
            path: '/fundraising/:id', component: () => import(/* webpackChunkName: "profile" */ "fundraising/show"), name: 'fundraising_page',
            async beforeEnter(to, from, next) {
                await store.dispatch('fundraising/loadOne', to.params.id)
                next()
            }
        },
        {path: '/embed/:id', component: () => import(/* webpackChunkName: "embed" */ "profile/embed_profile/embed_page"), name: 'embed_profile'},
        ...MerchShopRoutes,
        {path: '*', component: () => import(/* webpackChunkName: "error" */ "error_404")}
    ]
});

router.beforeEach((to, from, next) => {
    const user = store.state.auth.user;
    if (user && user.driver && !user.profile_enabled && to.path === '/home') {
        return next('/home/fundraising');
    }
    if (to.path.startsWith('/my-shop') && !user) {
        return next('/home/');
    }
    next();
});

// Start application
document.addEventListener('DOMContentLoaded', () => {
    const app = new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount()
    document.body.appendChild(app.$el)
})
