export const windowResize = {
  data() {
    return {
      isTablet: false,
      isMobile: false,
      isMobileSM: false,
      isSmall: false
    };
  },
  mounted() {
    this.checkWindowSize();
    window.addEventListener('resize', this.checkWindowSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWindowSize);
  },
  methods: {
    checkWindowSize() {
      this.isTablet = window.innerWidth <= 1023;
      this.isMobile = window.innerWidth <= 768;
      this.isMobileSM = window.innerWidth <= 640;
      this.isSmall = window.innerWidth <= 480;
    }
  }
};