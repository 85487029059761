import axios from 'plugins/axios-instance'
import {BASE_URL} from 'plugins/axios-instance'


function translateImages(images) {
    let keys = Object.keys(images)
    for (let key of keys) {
        images[key] = BASE_URL + "/shop/catalog/" + encodeURIComponent(btoa(images[key]) + '.png')
    }
    return images
}

const state = {
    availableProducts: [],
    orders: [],
    products: [],
    product: {},
    product_type: 'All',
};

const mutations = {
    SET_ORDERS(state, {orders}) {
        state.orders = orders
    },
    SET_PRODUCTS(state, {products}) {
        state.products = products
    },
    SET_PRODUCT(state, {product}) {
        state.product = product
    },
    SET_PRODUCT_TYPE(state, {product}) {
        state.product_type = product.product_type
    },
    SET_AVAILABLE_PRODUCTS(state, {fe_products}) {
        fe_products.map((p) => {
            if (p.catalog_product) {
                const printingMethod = p.catalog_product.eligiblePrintingMethods.indexOf('dtg') > -1 ? 'dtg' : 'dtf'
                state.availableProducts.push({
                    id: p.id,
                    name: p.name,
                    product_type: p.product_type,
                    price: p.price,
                    print_price: p.print_price,
                    availableVariants: p.catalog_product.variants.map((v) => {
                        return {
                            ...v,
                            images: translateImages(v.images),
                            thumbs: translateImages(v.thumbs)
                        }
                    }),
                    printLocations: p.catalog_product.printLocations,
                    default_images: [],
                    catalogProduct: p.catalog_product,
                    eligiblePrintingMethods: p.catalog_product.eligiblePrintingMethods,
                    printingMethod: printingMethod,
                    defaultDesign: {
                        front: [],
                        back: []
                    },
                    images: [],
                    colors: []
                })
            }
        })
    },
    UPDATE_PRODUCT_STATUS(state, { productId, status }) {
    const product = state.products.find(p => p.id === productId)
        if (product) {
            product.status = status
        }
    },
    REMOVE_PRODUCT(state, productId) {
        const index = state.products.findIndex(p => p.id === productId)
        if (index !== -1) state.products.splice(index, 1)
    },      
};

const actions = {
    async loadProducts({state, commit}){
        const {data} = await axios.get('shop/products')
        commit('SET_PRODUCTS', data)
    },
    async loadOrders({state, commit}){
        const {data} = await axios.get('shop/orders')
        commit('SET_ORDERS', data)
    },
    async loadProduct({commit, state}, productId) {
        let {data} = await axios.get(`public/products/${productId}`)
        commit('SET_PRODUCT', data)
    },
    async loadAvailableProducts({state, commit}) {
        if (state.availableProducts.length)
            return
        const {data} = await axios.get('shop/catalog')
        commit('SET_AVAILABLE_PRODUCTS', data)
    },
    async updateProductStatus({ commit }, { productId, status }) {
        try {
            const { data } = await axios.put(`shop/products/${productId}`, { status })
            commit('UPDATE_PRODUCT_STATUS', { productId, status: data.status })
        } catch (error) {
            console.error('Error updating product status:', error)
            throw error
        }
    },
    async deleteProduct({ commit }, productId) {
        try {
            await axios.delete(`/shop/products/${productId}`)
            commit('REMOVE_PRODUCT', productId)
        } catch (error) {
            console.error('Error deleting product:', error)
            throw error
        }
    },
}
const getters = {
    activeProducts(state){
        return state.products.filter(p => p.status == 'active')
    },
    draftProducts(state){
        return state.products.filter(p => p.status == 'draft')
    },
    filteredProducts: (state) => {
        if (state.product_type === 'All') {
            return state.availableProducts
        }
        return state.availableProducts.filter(product => 
            product.product_type === state.product_type
        )
    }
}
export default {
    namespaced: true,
    getters,
    state: state,
    mutations: mutations,
    actions: actions,
};