<template>
  <div>
    <div v-if="showSlider" class="photos">
      <div class="photos__photo"
      :key="'covphoto' + p.id" v-for="(p, $index) in photos" 
      :style="{ backgroundImage: 'url(' + (p.url || p.file) + ')' }">
        <div v-if="clickablePhotos"
             class="photos__image" 
             @click="selectImage($index)"></div>
        <div v-if="canUploadPhotos"
             class="photos__delete"
             @click="deletePhoto($index)">
          &times;
        </div>
      </div>
      <div v-if="canUploadPhotos && shouldShowUploader" class="photos__photo photos__photo_new">
        <label>
          <transition-group mode="out-in" name="fade">
            <span v-if="loading"
                  key="loading"
                  class="photos__photo_upload">
              <b-icon pack="mdi"
                      icon="loading"
                      custom-class="mdi-spin">
              </b-icon>
            </span>
            <img v-else key="img"
                        :src="`${require(`../../assets/images/photo_camera.svg`)}`"
                        class="photos__photo_icon" />
            <input type="file"
                   accept="image/*"
                   key="input"
                   @change="addPhoto"
                   @click="clearOversize">
            <p v-if="oversize" key="oversize" class="photos__upload-error">
              File size exceeds <strong>10</strong> MB
            </p>
          </transition-group>
        </label>
      </div>
      <slot name="caption"></slot>
    </div>

    <div v-else class="photos photos_mobile">
      <hooper :settings="this.hooperSettings" >
        <slide v-for="(image, $index) in photos"
               :key="'covphoto' + image.id">
          <div class="photos__photo"
               :style="{ backgroundImage: 'url(' +  (image.url || image.file || image.medium_url) + ')'}">
            <div v-if="clickablePhotos"
                 @click="selectImage($index)"
                 class="photos__image"></div>
            <div @click="deletePhoto($index)"
                 class="photos__delete" >
              &times;
            </div>
          </div>
        </slide>
        <slide v-if="shouldShowUploader">
          <div v-if="canUploadPhotos && shouldShowUploader" class="photos__photo photos__photo_new">
            <label>
              <transition-group mode="out-in" name="fade">
                <span v-if="loading"
                      key="loading"
                      class="photos__photo_upload">
                  <b-icon pack="mdi"
                          icon="loading"
                          custom-class="mdi-spin">
                  </b-icon>
                </span>
                <img v-else key="img"
                     :src="`${require(`../../assets/images/photo_camera.svg`)}`"
                     class="photos__photo_icon" />
                <input type="file"
                       accept="image/*"
                       key="input"
                       @change="addPhoto"
                       @click="clearOversize" />
                <p v-if="oversize" key="oversize" class="photos__upload-error">
                  File size exceeds <strong>10</strong> MB
                </p>
              </transition-group>
            </label>
          </div>
        </slide>
        <pagination slot="hooper-addons"></pagination>
      </hooper>
      <slot name="caption"></slot>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide, Pagination } from "hooper";
import { SLIDER_PHOTO_HOOPER_SETTINGS } from "../../libs/hooper_settings.js";

export default {
  components: {
    Hooper, Slide, Pagination
  },
  props: {
    photos: {
      type: Array,
      required: true,
      default: () => []
    },
    oversize: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    canUploadPhotos: {
      type: Boolean,
      default: true
    },
    clickablePhotos: {
      type: Boolean,
      default: false
    },
    maxPhotosAmount: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      isMobile: null
    }
  },

  mounted() {
    this.checkWindowSize();
    window.addEventListener('resize', this.checkWindowSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWindowSize);
  },

  computed: {
    shouldShowUploader() {
      return this.maxPhotosAmount === null 
        ? true 
        : this.photos.length < this.maxPhotosAmount
    },
    hooperSettings() {
      return SLIDER_PHOTO_HOOPER_SETTINGS;
    },
    showSlider() {
      return (this.maxPhotosAmount === 1 || this.clickablePhotos) || !this.isMobile;
    },
  },

  methods: {
    addPhoto(event) {
      this.$emit("onPhotoUpload", event)
    },
    deletePhoto(index) {
      this.$emit("onPhotoDelete", index)
    },

    addVideo(event) {
      this.$emit("onVideoUpload", event)
      console.log('child event:', event.target)
    },
    clearOversize() {
      this.$emit('clearOversize');
    },
    selectImage(index) {
      this.$emit("onSelectImage", index)
    },
    checkWindowSize() {
      this.isMobile = window.innerWidth <= 480;
    }
  }
}
</script>

<style lang="sass" scoped>
  .photos
    display: flex
    flex-wrap: wrap
    gap: 20px
    overflow: hidden

    &__image
      width: 100%
      height: 100%
      cursor: pointer

    &__photo
      width: 180px
      height: 110px
      border-radius: 4px
      background-size: cover
      background-repeat: no-repeat
      background-position: center
      position: relative

      &_icon, &_upload
        position: absolute
        top: 50%
        right: 50%
        transform: translate(50%, -50%)
        font-size: 45px
        line-height: 0px
        display: block

      &_new
        text-align: center
        cursor: pointer
        border-radius: 4px
        border: 1px solid rgba(255, 255, 255, 0.2)
        transition: all .2s ease
        &:hover
            background-color: rgba(255, 255, 255, 0.05)

        label
          position: relative
          display: block
          width: 100%
          height: 100%
          cursor: pointer

        input
          position: absolute
          visibility: hidden

    &__delete
      position: absolute
      width: 20px
      height: 20px
      border-radius: 50%
      text-align: center
      font-weight: bold
      font-size: 20px
      line-height: 20px
      display: block
      background: #F33E31
      top: 8px
      right: 8px
      color: white
      cursor: pointer

    &__upload-error
      position: absolute
      bottom: -20px
      margin-top: 8px
      text-align: left
      font-size: 12px
      color: #F33E31
      white-space: nowrap
      pointer-events: none
      strong
        color: inherit

    &_mobile
      gap: 0

    @media screen and (max-width: 480px) 

      &_mobile
        .photos__photo
          width: 162px

  .hooper
    height: 140px

    &-list
      overflow: visible
</style>