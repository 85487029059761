<template>
  <div class="i">
    <div
      :class="[
        'columns',
        { 'is-vcentered': isVcentered },
        { 'is-gapless': isMobile }
      ]"
    >
      <div v-if="hasLabel" class="column is-4 i__label">
        <slot />
      </div>
      <div :class="['column', `is-${columnWidth}`, customClass]">
        <div class="multiple" ref="dropdownRef">
          <div @click="toggleDropdown" :class="['input', { open: isDropdownOpen }, {'is-danger' :invalid}]">
            <span class="multiple__placeholder">{{ placeholder }}</span>
          </div>
          <p class="help is-danger" :style="computedHelpHeight" v-if="invalid">
            <slot name="error"></slot>
          </p>
          <transition name="slide" mode="out-in">
            <div class="multiple__dropdown" v-if="isDropdownOpen">
              <ul class="multiple__list">
                <li
                  v-for="option in options"
                  :key="option.value"
                  class="multiple__option"
                >
                  <label>
                    <input
                      type="checkbox"
                      :value="option.value"
                      v-model="selectedValues"
                      @change="toggleOption(option)"
                    />
                    {{ option.name }}
                    <transition name="fade">
                      <b-icon
                        v-if="selectedValues.includes(option.value)"
                        pack="mdi"
                        icon="check"
                        size="is-small"
                      ></b-icon>
                    </transition>
                  </label>
                </li>
              </ul>
            </div>
          </transition>
          <transition-group 
            v-if="selectedValues.length" 
            name="slide-fade" 
            class="multiple__selected-list ml-0"
          >
            <b-tag 
                v-for="selectedValue in selectedValues"
                :key="selectedValue"
                closable
                aria-close-label="Remove category"
                @close="removeOption(selectedValue)">
                {{ getOptionName(selectedValue) }}
            </b-tag>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { windowResize } from '../../mixins/window_resize'

export default {
  mixins: [windowResize],
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    columnWidth: {
      type: Number,
      default: 8
    },
    hasLabel: {
      type: Boolean,
      default: true
    },
    placeholder: '',
    customClass: '',
    isVcentered: true,
    invalid: false,
  },
  data() {
    return {
      isDropdownOpen: false,
      selectedValues: [...this.value]
    }
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
    document.addEventListener('keydown', this.handleEscapeKey);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
    document.removeEventListener('keydown', this.handleEscapeKey);
  },
  watch: {
    value(newValue) {
      this.selectedValues = [...newValue]
    }
  },
  computed:{
    computedHelpHeight() {}
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
    },
    closeDropdown() {
      this.isDropdownOpen = false
    },
    toggleOption(option) {
      this.$emit('input', this.selectedValues)
    },
    removeOption(value) {
      this.selectedValues = this.selectedValues.filter(v => v !== value)
      this.$emit('input', this.selectedValues)
    },
    getOptionName(value) {
      const option = this.options.find(opt => opt.value === value)
      return option ? option.name : ''
    },
    handleOutsideClick(event) {
      if (!this.$refs.dropdownRef || !this.$refs.dropdownRef.contains(event.target)) {
        this.closeDropdown();
      }
    },
    handleEscapeKey(event) {
      if (event.key === 'Escape') {
        this.closeDropdown();
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.multiple
  position: relative

  .input
    transition: all .2s ease-in-out
    &::after
      margin-top: -0.4375em
      position: absolute
      top: 50%
      right: 1.125em
      transform: rotate(-45deg)
      height: 0.625em
      width: 0.625em
      display: block
      border: 3px solid transparent
      border-radius: 2px
      border-right: 0
      border-top: 0
      content: ""
      pointer-events: none
      transform-origin: center
      border-color: #0CBEE5
      z-index: 4
      transition: all .2s ease-in-out
    &:hover
      background-color: rgba(255, 255, 255, 0.08)
      border-color: rgba(255, 255, 255, 0.2)

      &::after
        border-color: rgba(12, 190, 229, 0.4)
        
    &.open
      background-color: rgba(255, 255, 255, 0.08)
      border-color: #0CBEE5
      &::after
        top: 58%
        transform: rotate(136deg)


  &__placeholder
    color: rgba(255, 255, 255, .5)

  &__dropdown
    position: absolute
    top: 48px
    right: 0
    left: 0
    z-index: 5
    border-radius: 4px
    border: 1px solid rgba(255, 255, 255, .05)
    background: #3d3d3d

  &__list
    margin: 0
    display: flex
    flex-direction: column
    list-style-type: none

  &__option
    border-bottom: 1px solid rgba(255, 255, 255, .05)
    &:last-child
      border-bottom: none

    label
      padding: 0 10px
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      height: 100%
      cursor: pointer
      &:hover
        background: #484848

      input
        display: none

  &__selected-list
    margin: 16px 0 0
    display: flex
    flex-wrap: wrap
    gap: 12px
    list-style-type: none

  .tag
    padding: 10px
    height: auto
    font-size: 14px
    line-height: 20px
    background: transparent
    border: 1px solid rgba(255, 255, 255, .5)
    transition: all .2s ease-in-out
    .is-small.delete
      background: tomato
    &:hover
      background: rgba(255, 255, 255, .05)

  .slide-fade-enter-active 
    transition: all 0.2s ease-out

  .slide-fade-leave-active 
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1)

  .slide-fade-enter-from,
  .slide-fade-leave-to
    transform: translateX(-15px)
    opacity: 0

.i__label
  @media screen and (max-width: 768px)
    max-height:  38px
    font-size: 14px
    line-height: 28px

</style>

<style lang="sass">
p.help
  font-weight: normal
  font-size: .75rem
  margin: 0
  line-height: 20px

.multiple__selected-list
  .tag
    padding: 10px 14px 10px 10px !important
    gap: 5px

    a.delete
      padding: 10px
      background: transparent url('./close.svg') center / cover no-repeat
      &:before, &::after
        display: none

</style>
